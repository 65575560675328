import React, { useState } from "react";
import "./Chart.css";
import Spinner from 'react-bootstrap/Spinner';
import DataNotFound from "../DataNotFound/DataNotFound";

import {
  LineChartRenderer,
  CustomBarChartRenderer,
  BarChartRenderer,
  CustomLineChartRenderer,
  AreaChartRenderer,
  ApexPieChartRenderer,
  PieChartRenderer,
  TableRenderer,
  ComparisonTableRenderer
} from "./Components";

const LoadingSpinner = () => (
  <div className="d-flex w-100 h-50 justify-content-center align-items-center flex-column gap-1" style={{ minHeight: "200px" }}>
    <Spinner animation="border" role="status" variant="loader-variant" />
    <div className="text-loader-variant">Loading...</div>
  </div>
);

const chartComponents = {
  bar: BarChartRenderer,
  customBar: CustomBarChartRenderer,
  pie: PieChartRenderer,
  apexpie: ApexPieChartRenderer,
  area: AreaChartRenderer,
  line: LineChartRenderer,
  customLine: CustomLineChartRenderer,
  number: ({ resultSet }) => (
    <>
      <div className="d-flex flex-col gap-4 bg-light text-dark">
        {resultSet.seriesNames().map((s) => (
          <div className="p-4" key={s.key}>{resultSet.totalRow()[s.key]}</div>
        ))}
      </div>
    </>
  ),
  table: TableRenderer,
  ComparisonTable: ComparisonTableRenderer,
};

const RenderChart = ({ chartType, resultSet, error, noDataFound ,...otherProps }) => {
  
  const [predefinedHeadings,setPredefinedHeadings] = useState({}); // If the view will get change then store the range filter modal values and headings in this state (Parent component) later send it next component
  if (error || !resultSet || Object?.keys(resultSet).length === 0) {
    return noDataFound ? <div className="d-flex justify-content-center"> <DataNotFound/></div> : <LoadingSpinner />;
  }
  const getHeadingsOnViewChange = (data) => {
    const parsedData = JSON.parse(JSON.stringify(data));
    setPredefinedHeadings(parsedData)
  }
  const ChartComponent = chartComponents[chartType] || LineChartRenderer;
  return <ChartComponent predefinedHeadings={predefinedHeadings} getHeadingsOnViewChange={getHeadingsOnViewChange} resultSet={resultSet} {...otherProps} />;
};

const Chart = (props) => {
  const {
    type = "bar",
    pivot = {},
    classes = "",
    display = "",
    percentageShow = true,
    childrenOneData = {},
    childrenOneHeader = "-",
    page = "",
    FunnelViews = [],
    CurrentViewType = "Performance View",
    paginationResultSet = {},
    showFilter = false,
    summaryTotal={},
    noDataFound=false,
    summaryComparisonTotal={},
    pageName="",
    customDownloadURL = "",
  } = props;

  return (
    <RenderChart
      resultSet={props.resultSet}
      shimmerEffect={props.shimmerEffect}
      error=""
      pivotConfig={pivot}
      chartType={type}
      customClasses={classes}
      displayType={display}
      percentageShow={percentageShow}
      props={props}
      children_1={childrenOneData}
      children_1_header={childrenOneHeader}
      page={page}
      FunnelViews={FunnelViews}
      CurrentViewType={CurrentViewType}
      paginationResultSet={paginationResultSet}
      showFilter={showFilter}
      summaryTotal={summaryTotal}
      summaryComparisonTotal={summaryComparisonTotal}
      noDataFound={noDataFound}
      pageName={pageName}
      customDownloadURL={customDownloadURL}
    />
  );
};

export default Chart;
