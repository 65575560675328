import React from "react";
import { Stack } from "react-bootstrap";
import "./sectionTitle.css";
const SectionTitle = (props) => {
  return (
    <div className="section-title-container">
      <h3 style={{margin:"0"}} className="title">
        {props.sectionTitleHeading}{" "}
        {props.isComingSoon && (
          <span style={{ fontSize: "12px" }} className="text-danger">
            (Coming Soon...!)
          </span>
        )}
      </h3>
    </div>
  );
};

export default SectionTitle;
