export const ATTRIBUTE_CONDITIONS_MAPPER = {
    "maximum_retail_price": "Product MRP",
    "sale_price": "Product SP",
    "discount_percent": "Product Discount",
    "star_rating": "Product Star rating",
    "reviews": "Product Reviews",
    "name": "Product Name",
    "size": "Product - Size",
    "color": "Product - Color",
    "launch_date": "Launched Date",
    "gender":"Gender",
    "ad_status":"Products not advertised",
    "best_selling_rank":"BSR",
    "top_best_sellers_rank":"Top BSR",
    "cut_size_percent":"Cut Size *"
}

export const GENERAL_OPERATORS_MAPPER = {
    "EQUALS": "=",
    "NOT_EQUALS": "!=",
    "GREATER_THAN": ">",
    "LESS_THAN": "<",
    "GREATER_THAN_OR_EQUAL": ">=",
    "LESS_THAN_OR_EQUAL": "<=",
    "CONTAINS": "contains",
    "not contains": "NOT_CONTAINS",
}

export const PERFORMANCE_CONDTIONS_MAPPER = {
    "sale_value":"Revenue",
    "top_sale_value":"Top Selling",
    "top_conversion_rate":'Top Conversion',
    "top_roas":"Top ROAS",
    "quantity":"Available Inventory",
    "total_revenue":"Total Revenue",
    "tacos":"TACOS",
    "troas":"TROAS",
    "total_asp":"ASP",
    "total_units_sold":"Total Units Sold",
    "roas":"ROAS",
    "conversion_rate":"Conversion Rate",
    "ctr":"CTR"
}

export const PRODUCT_SET_DURATION_MAPPER = {
    "last_14_days":"14 days",
    "last_7_days":"7 days",
    "last_3_days":"3 days",
    "last_1_day":"1 day",
}
export const PRODUCT_SET_REFRESH_DURATION = { 
    "1_days":"1 Day",
    "3_days":"3 Days",
    "7_days":"7 Days",
    "14_days":"14 Days",
    "21_days":"21 Days",
}

export const STOP_LOSS_LOGS_DATAHEADS =  [
    {
      fieldName: "Impact",
      id: "impact",
      apiKey: "decision?.extraInfo?.product_count",
      minWidth: "152px",
    },
    {
      fieldName: "Ad Spend Saved",
      id: "ad_spend_saved",
      apiKey: "decision?.extraInfo?.adSpendSaved",
    },

    {
      fieldName: "Effective Status",
      id: "effective_status",
      apiKey: "effective_change",
    },
    {
      fieldName: "Activity Time",
      id: "activity_time",
      apiKey: "created_at",
    },
    {
      fieldName: "Exclusion Configuration",
      id: "exclusion_case",
      apiKey: "rule_config?.ruleTasks?.0?.entityMetricFilter?.cubeFilters",
      minWidth: "340px",
    },
    {
      fieldName: "Inclusion Configuration",
      id: "inclusion_case",
      apiKey: "rule_config?.ruleTasks?.1?.entityMetricFilter?.cubeFilters",
      minWidth: "340px",
    },
    {
      fieldName: "User",
      id: "user",
      apiKey: "rule_config?.modifiedBy",
    },
  ]

export const PRODUCT_SET_LOGS_DATAHEADS =  [
    {
      fieldName: "Impact",
      id: "impact",
      apiKey: "decision?.extraInfo?.product_count",
      minWidth: "152px",
    },
    {
      fieldName: "Campaign",
      id: "campaign_name",
      apiKey: "extra_info_json?.populated_entity_list",
    },

    {
      fieldName: "Adgroup ",
      id: "adset_name",
      apiKey: "extra_info_json?.populated_entity_list",
    },
    {
      fieldName: "Activity Time",
      id: "activity_time",
      apiKey: "created_at",
    },
    {
      fieldName: "Configuration",
      id: "configuration",
      apiKey: "product_set_config?.cubeFilters",
      minWidth: "340px",
    },
  ]

export const PRODUCT_SET_LIST_DATAHEADS = [
  {
      "fieldName": "Status",
      "minRange": "-",
      "maxRange": "-",
      "id": "status",
      "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false
  },
  {
      "fieldName": "Product Set",
      "minRange": "-",
      "maxRange": "-",
      "id": "product_set",
      "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false
  },
  {
      "fieldName": "Refresh Frequency",
      "minRange": "-",
      "maxRange": "-",
      "id": "refresh_frequency",
      // "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false,
      "isFindTaskActive": false,
      "status": false,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Spends",
      "minRange": "-",
      "maxRange": "-",
      "id": "spends",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Revenue",
      "minRange": "-",
      "maxRange": "-",
      "id": "revenue",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Orders",
      "minRange": "-",
      "maxRange": "-",
      "id": "orders",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "ROAS",
      "minRange": "-",
      "maxRange": "-",
      "id": "roas",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true
  },
  {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },{
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
      isHeadRightAlign: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    }, {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    }
]
