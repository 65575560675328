import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack } from "react-bootstrap";
import TableRb from "../../components/Tables/tableRb";
import Styles from "./StopLossList.module.css";
import logsIcon from "../../assets/icons/logsIcon.svg";

import { Link, useNavigate } from "react-router-dom";
import { Pencil } from "react-bootstrap-icons";
import SwitchBtn from "../../components/Buttons/switchBtn";
import axios from "axios";
import { useGlobalContext } from "../../context/global";
import FilterPreview from "./subComponents/FilterViewer/FilterViewer";
import moment from "moment";
import StopLossLogsModal from "./subComponents/StopLossLogsModal/StopLossLogsModal";
import { toast } from "react-toastify";
import { getCubeFiltersByActionType } from "../../helper/Quadrant/quadrantHelper";

const StopLossList = () => {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const { clientId } = useGlobalContext();

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [showLogsModal, setShowLogsModal] = useState("");

  const [dataHeads, setdataHeads] = useState([
    {
      fieldName: "Status",
      id: "status",
    },
    {
      fieldName: "Rule Name",
      id: "name",
    },

    {
      fieldName: "Updated At",
      id: "updated_at",
    },
    {
      fieldName: "Frequency",
      id: "frequency",
    },
    {
      fieldName: "Exclusion Case",
      id: "exclusion_case",
    },
    {
      fieldName: "Inclusion Case",
      id: "inclusion_case",
    },
    {
      fieldName: "Action",
      id: "action",
    },
  ]);

  const [ruleList, setRuleList] = useState([]);

  const navigate = useNavigate();

  const handleRedirectForUpdate = (dataId, dataIndex) => {
    const url = `/product-analytics?reportType=Product&viewtype=Performance+View&page=stoploss&pslRuleId=${dataId}`;
    const stopLossData = ruleList?.find((ruleSet) => ruleSet?.id === dataId);
    navigate(url, { state: { stopLossData } });
  };

  async function handleUpdateRuleStatus(pslRuleId, status) {
    let data = {};
    data = await axios.patch(
      `${process.env.REACT_APP_ADS_API}/client/${clientId}/automation-rule-set/${pslRuleId}?source=amazon`,
      { status: status ? "Active" : "Inactive" }
    );

    if (data.data?.success) {
      toast.success("Stoploss Rule updated successfully!");
      navigate("/stop-loss-list");
    } else {
      toast.error("Error while saving Stoploss Rule. Try Again!");
    }
  }

  async function fetchRuleList() {
    let url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/automation-rule-set?source=amazon`;
    setIsFetchingData(true);
    await axios
      .get(url)
      .then((ruleList) => {
        setRuleList(
          ruleList?.data?.filter((rule) => rule?.type === "AMAZON_STOP_LOSS")
        );
      })
      .catch((error) => {
        console.log("Error while fetching Stoploss Rules", error);
        toast.error("Error while fetching Stoploss Rules. Try Again!",{toastId: "fetchStopLossRulesError"});
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }

  useEffect(() => {
    fetchRuleList();
  }, []);

  let tableBody = (
    <div className="main-campaignlist-table-body-container smart-ads-list-table lsit-table-scrollbar">
      {ruleList?.length ? (
        <table className="campaign-list-table" id="smart-ads-table">
          <thead className="fixed-table">
            <tr className="main-table-row-fixed">
              {dataHeads?.map((th, mainIndex) => {
                return (
                  <th className={"campaig-list-head"}>
                    <span
                      className="captital-first-letter"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {th.fieldName.toLowerCase()}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          {ruleList?.length ? (
            <tbody className="another-tbody">
              {ruleList?.map((rowData, index) => {
                return (
                  <tr key={index + 1}>
                    {dataHeads?.map((column, index1) => {
                      let cellContent;
                      switch (column?.id) {
                        case "status":
                          cellContent = (
                            <td className="text-nowrap">
                              <SwitchBtn
                                defaultChecked={rowData?.status === "Active"}
                                onChange={(e) => {
                                  handleUpdateRuleStatus(
                                    rowData?.id,
                                    e.target.checked
                                  );
                                }}
                              />
                            </td>
                          );
                          break;

                        case "action":
                          cellContent = (
                            <td className="text-nowrap">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "6px",
                                }}
                              >
                                <Pencil
                                  size={15}
                                  onClick={() => {
                                    handleRedirectForUpdate(rowData?.id, index);
                                  }}
                                  className="cursor-pointer"
                                />
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={logsIcon}
                                  alt="logsIcon"
                                  onClick={() => {
                                    setShowLogsModal(rowData?.name);
                                  }}
                                />
                              </div>
                            </td>
                          );
                          break;
                        case "updated_at":
                          cellContent = (
                            <td key={index1 + 1} className="td-with-loader">
                              {rowData[column?.id]
                                ? moment(rowData[column?.id]).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </td>
                          );
                          break;
                        case "exclusion_case":
                          cellContent = (
                            <td key={index1 + 1} className="td-with-loader">
                              {FilterPreview({
                                filters: JSON.parse(
                                  getCubeFiltersByActionType(
                                    rowData?.rule_tasks,
                                    "PAUSE_PRODUCTS"
                                  ) ?? "{}"
                                )?.query?.filters,
                              })}
                            </td>
                          );
                          break;
                        case "inclusion_case":
                          cellContent = (
                            <td key={index1 + 1} className="td-with-loader">
                              {FilterPreview({
                                filters: JSON.parse(
                                  getCubeFiltersByActionType(
                                    rowData?.rule_tasks,
                                    "ENABLE_PRODUCTS"
                                  ) ?? "{}"
                                )?.query?.filters,
                              })}
                            </td>
                          );
                          break;
                        case "frequency":
                          cellContent = (
                            <td key={index1 + 1} className="td-with-loader">
                              <p
                                style={{ minWidth: "130px" }}
                                className={Styles.blueBadge}
                              >
                                {rowData?.schedule_config?.day_frequency
                                  ? "Running " +
                                    rowData?.schedule_config?.day_frequency
                                  : "-"}
                              </p>
                            </td>
                          );
                          break;

                        default:
                          cellContent = (
                            <td key={index1 + 1} className="td-with-loader">
                              {rowData[column?.id] ? rowData[column?.id] : "-"}
                            </td>
                          );
                      }

                      return <>{cellContent}</>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <div>{/* <p>NO DATA FOUND</p> */}</div>
          )}
        </table>
      ) : (
        <div>{/* <p>NO DATA FOUND</p> */}</div>
      )}
    </div>
  );

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <div
        className="toolkitWrapper"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="bold">Stop Loss</h3>

        <div className="nav-button-container">
          <Link
            to={
              "/product-analytics?reportType=Product&viewtype=Performance+View&page=stoploss"
            }
          >
            {" "}
            <button
              insideBtnText="Create Portfolio"
              portfolio={true}
              id={"create-product-set"}
              variant="primary"
              className="primary-active-btn"
            >
              + Create Stop Loss
            </button>{" "}
          </Link>
        </div>
      </div>
      <div>
        <div className="campaign-list-table-data">
          <Stack className="campaign-list-main-table">
            <TableRb
              table={tableBody}
              shimmer={isFetchingData}
              tableHeads={dataHeads?.map((head) => { return { ...head,fieldName: head.fieldName, shortTitle:head.fieldName } })}
              noDataFound={
                 !isFetchingData && !ruleList?.length
              }
            ></TableRb>
          </Stack>
        </div>
      </div>
      <StopLossLogsModal
        showLogsModal={showLogsModal}
        pageName='stoploss'
        setShowLogsModal={setShowLogsModal}
      />
    </div>
  );
};

export default StopLossList;