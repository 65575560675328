import { useEffect, useState } from "react";
import styles from "./StopLossFilters.module.css";
import ReactSelect from "react-select";
import FilterWidget from "../../../../components/Filter/Widget";
import arrowUpIcon from "../../../../assets/icons/arrow_up_icon.svg";
import arrowDownIcon from "../../../../assets/icons/down_arrow.svg";

import {
  FiltersAmazonDataQuadrant,
  FiltersDimensionDataQuadrant,
  FiltersMeasuresDataQuadrant,
} from "../../../../components/Filter/FilterData/quadrantFilter";
import CustomTextField from "../../../../components/Filter/subComponents/CustomTextField/CustomTextField";
import { processFields } from "../../../../components/Filter/subComponents/filterHelper";
import { useGlobalContext } from "../../../../context/global";
import { ALL_WEEK_DAYS } from "../../../../helper/Quadrant/quadrantHelper";

export default function StopLossFilters(props) {
  const { clientId } = useGlobalContext();
  const [expandExclusionFilters, setExpandExclusionFilters] = useState(true);
  const [expandInclusionFilters, setExpandInclusionFilters] = useState(false);
  const STOP_LOSS_DATE_OPTIONS = [
    { value: "last_3d", label: "Last 3 days", type: "" },
    { value: "last_7d", label: "Last 7 days", type: "" },
    { value: "last_30d", label: "Last 30 days", type: "" },
  ];
  const [selectedDay, setSelectedDay] = useState(
    STOP_LOSS_DATE_OPTIONS.find((option) => option.value === props.customDate)
  );
  const [inclusionSelectedDay, setInclusionSelectedDay] = useState(
    STOP_LOSS_DATE_OPTIONS.find(
      (option) => option.value === props.inclusionCustomDate
    )
  );
  const [stoplossRuleDetail, setStoplossRuleDetail] = useState({
    ...props.stoplossRuleDetail,
  });
  const [errors, setErrors] = useState(props?.errors);

  const exclusionPercentOptions = [
    { label: "Select", value: null },
    { label: "10%", value: 10 },
    { label: "20%", value: 20 },
    { label: "30%", value: 30 },
    { label: "40%", value: 40 },
    { label: "50%", value: 50 },
  ];

  const frequencyOptions = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-weekly", value: "Biweekly" },
    { label: "Custom", value: "Custom" },
  ];

  useEffect(() => {
    setSelectedDay(
      STOP_LOSS_DATE_OPTIONS.find((option) => option.value === props.customDate)
    );
  }, [props.customDate]);

  useEffect(() => {
    setInclusionSelectedDay(
      STOP_LOSS_DATE_OPTIONS.find(
        (option) => option.value === props.inclusionCustomDate
      )
    );
  }, [props.inclusionCustomDate]);

  useEffect(() => {
    setStoplossRuleDetail({ ...props.stoplossRuleDetail });
  }, [props.stoplossRuleDetail]);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  useEffect(() => {
    const init = async () => {
      const precessedDimensionData = await processFields(
        FiltersDimensionDataQuadrant.fields,
        clientId,
        []
      );
      FiltersDimensionDataQuadrant.fields = precessedDimensionData;
      const precessedMeasuresData = await processFields(
        FiltersMeasuresDataQuadrant.fields,
        clientId,
        []
      );
      FiltersMeasuresDataQuadrant.fields = precessedMeasuresData;
      const precessedAmazonData = await processFields(
        FiltersAmazonDataQuadrant.fields,
        clientId,
        []
      );
      FiltersAmazonDataQuadrant.fields = precessedAmazonData;
    };
    init();
  }, [clientId]);

  return (
    <>
      <div className={styles.pageFilterWrapper}>
        <div
          style={{
            display: "flex",
            padding: expandExclusionFilters ? "12px 24px" : "12px 24px",
            justifyContent: "space-between",
            borderBottom: expandExclusionFilters ? "1px solid #C9CCCF" : "0px",
          }}
        >
          <div style={{ fontSize: "16px", lineHeight: "1.3", color: "#000" }}>
            Exclusion Details
          </div>
          <div
            onClick={(e) => setExpandExclusionFilters(!expandExclusionFilters)}
          >
            <img
              height={10}
              src={expandExclusionFilters ? arrowUpIcon : arrowDownIcon}
              alt="up"
            />
          </div>
        </div>
        <div
          style={{
            opacity: expandExclusionFilters ? 1 : 0,
            transform: expandExclusionFilters ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "center",
            transition: "opacity 0.3s ease-in-out, transform 0.2s ease-in-out",
            visibility: expandExclusionFilters ? "visible" : "hidden",
            maxHeight: expandExclusionFilters ? "max-content" : "0", // Adjust based on content's maximum height
          }}
        >
          <FilterWidget
            config={{
              campaignAmazon: FiltersAmazonDataQuadrant,
              measure: FiltersMeasuresDataQuadrant,
              dimension: FiltersDimensionDataQuadrant,
            }}
            filters={props.filterModuleFilters}
            output={props.handleFilterOutput}
            prefixMember={""}
            type="page"
            page={"stoploss"}
            // summary={"onApplied"}
            fetchingData={props.fetchingData}
            isApplied={props.handleFilterApplied}
            narrowFilters={false}
            daysOptions={STOP_LOSS_DATE_OPTIONS}
            selectedDay={selectedDay}
            sidebar={true}
            default={"metrics"}
            showDays={true}
            id={"exclusion"}
            campaignFilter={true}
            handleSelectedDay={props.handleCustomDate}
            calloutTitle="Select your filters"
            calloutContent="+ Add Filter"
          />
        </div>
      </div>

      <div className={styles.pageFilterWrapper}>
        <div
          style={{
            display: "flex",
            padding: expandInclusionFilters ? "12px 24px" : "12px 24px",
            justifyContent: "space-between",
            borderBottom: expandInclusionFilters ? "1px solid #C9CCCF" : "0px",
          }}
        >
          <div style={{ fontSize: "16px", lineHeight: "1.3", color: "#000" }}>
            Inclusion Details
          </div>
          <div
            onClick={(e) => setExpandInclusionFilters(!expandInclusionFilters)}
          >
            <img
              height={10}
              src={expandInclusionFilters ? arrowUpIcon : arrowDownIcon}
              alt="up"
            />
          </div>
        </div>
        <div
          style={{
            opacity: expandInclusionFilters ? 1 : 0,
            transform: expandInclusionFilters ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "center",
            transition: "opacity 0.3s ease-in-out, transform 0.2s ease-in-out",
            visibility: expandInclusionFilters ? "visible" : "hidden",
            maxHeight: expandInclusionFilters ? "max-content" : "0", // Adjust based on content's maximum height
          }}
        >
          <FilterWidget
            config={{
              campaignAmazon: FiltersAmazonDataQuadrant,
              measure: FiltersMeasuresDataQuadrant,
              dimension: FiltersDimensionDataQuadrant,
            }}
            filters={props.inclusionFilterModuleFilters}
            output={props.handleInclusionFilterOutput}
            prefixMember={""}
            type="page"
            sidebar={true}
            default={"metrics"}
            page={"stoploss"}
            // summary={"onApplied"}
            fetchingData={false}
            isApplied={props.handleFilterApplied}
            narrowFilters={false}
            daysOptions={STOP_LOSS_DATE_OPTIONS}
            selectedDay={inclusionSelectedDay}
            showDays={true}
            handleSelectedDay={props.handleInclusionCustomDate}
            campaignFilter={true}
            id={"inclusion"}
            calloutTitle="Select your filters"
            calloutContent="+ Add Filter"
          />
        </div>
      </div>

      <div className={styles.pageFilterWrapper}>
        <div>
          <div className={styles.pageContent}>
            <div className={styles.container}>
              <div
                className={styles.firstDiv}
                style={{ display: "flow", width: "100%" }}
              >
                <div className={styles.innerContainer}>
                  <div style={{ width: "500px" }}>
                    <div
                      className={styles.label}
                      style={{ marginBottom: "10px" }}
                    >
                      Rule Name
                    </div>
                    <CustomTextField
                      placeholder={"Enter Rule Name"}
                      value={stoplossRuleDetail?.name}
                      type="text"
                      error={errors?.name ?? "" ? true : false}
                      disabled={props.pslRuleId ? true : false}
                      onChange={(e) =>
                        props?.handleStoplossRuleDetail("name", e)
                      }
                    />
                  </div>
                </div>
                <div
                  className={styles.innerContainer}
                  style={{ margin: "12px 0" }}
                >
                  <div className={styles.label} style={{ fontSize: "14px" }}>
                    Don&apos;t exclude if total exclusion is {">"}{" "}
                  </div>
                  <div style={{ width: "100px" }}>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="Select Operator"
                      value={exclusionPercentOptions.find(
                        (option) =>
                          option.value ===
                          stoplossRuleDetail?.max_percent_exclude
                      )}
                      isSearchable={false}
                      name="stop_loss_frequency"
                      options={exclusionPercentOptions}
                      placeholder={"Select Option"}
                      menuPlacement={"auto"}
                      onChange={(selectedOption) =>
                        props?.handleStoplossRuleDetail(
                          "max_percent_exclude",
                          selectedOption?.value
                        )
                      }
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: "36px",
                          fontSize: "14px",
                          borderColor:
                            errors?.max_percent_exclude ?? ""
                              ? "#FF4D4F"
                              : "#AEB4B9",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          maxHeight: "180px",
                          overflow: "hidden",
                          fontSize: "14px",
                          zIndex: "99",
                        }),
                        menuList: (provided, state) => ({
                          ...provided,
                          fontSize: "14px",
                          maxHeight: "150px", // Ensure the menu list also respects the max height
                        }),
                      }}
                    />
                  </div>
                  <div className={styles.label} style={{ fontSize: "14px" }}>
                    {" "}
                    of products
                  </div>
                </div>
                <div
                  className={styles.label}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  {/* checkbox */}
                  <input
                    type="checkbox"
                    checked={stoplossRuleDetail?.recommend_only == true}
                    onChange={(e) =>
                      props?.handleStoplossRuleDetail(
                        "recommend_only",
                        e.target.checked
                      )
                    }
                    id="checkbox_recommend"
                  />
                  <label
                    htmlFor="checkbox_recommend"
                    style={{ marginLeft: "5px" }}
                  >
                    Don&apos;t apply Stop Loss, only give recommendations
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.container}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "6px",
                  height: "fit-content",
                }}
                className={styles.runStopLoss}
              >
                <b>Schedule Repeat On</b>

                <div className={styles.lineClass} style={{ fontSize: "14px" }}>
                  Product Stop Loss running schedule will be
                  <div style={{ minWidth: "25%", maxWidth: "230px" }}>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="Select Operator"
                      value={frequencyOptions.find(
                        (option) =>
                          option.value === stoplossRuleDetail?.frequency
                      )}
                      isSearchable={false}
                      name="stop_loss_frequency"
                      options={frequencyOptions}
                      placeholder={"Select Option"}
                      menuPlacement={"auto"}
                      onChange={(selectedOption) =>
                        props?.handleStoplossRuleDetail(
                          "frequency",
                          selectedOption?.value
                        )
                      }
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: "36px",
                          fontSize: "14px",
                          // borderColor:
                          //   conditionErrors.some((error) => error.filter) &&
                          //   condition.filter === ""
                          //     ? "#FF4D4F"
                          //     : "#AEB4B9",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          maxHeight: "180px",
                          overflow: "hidden",
                          fontSize: "14px",
                          zIndex: "99",
                        }),
                        menuList: (provided, state) => ({
                          ...provided,
                          fontSize: "14px",
                          maxHeight: "150px", // Ensure the menu list also respects the max height
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className={styles.daysFrequency}>
                  <div
                    className={styles.lineClass}
                    style={{ color: "black", marginBottom: "10px" }}
                  >
                    Repeat On
                  </div>
                  {ALL_WEEK_DAYS.map((day, index) => (
                    <div
                      key={index}
                      className={`checkbox_frequncy ${
                        stoplossRuleDetail?.stoploss_running_days?.includes(day)
                          ? styles.selectedCircle
                          : styles.unselectedCircle
                      }`}
                      onClick={() => {
                        if(stoplossRuleDetail?.frequency === "Daily") {
                          return;
                        }
                        props?.handleStoplossRuleDetail(
                          "stoploss_running_days",
                          day
                        );
                      }}
                    >
                      {day.charAt(0).toUpperCase() + day.slice(1)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {props?.dateOffset && props.dateOffset > 1 && (
              <div
                style={{
                  color: "#6d7175",
                  display: "flex",
                  gap: "4px",
                  alignItems: "self-start",
                  padding: "0px 8px",
                }}
              >
                <div>*</div>
                <div>
                  Data picked up for the creation of Product Stop Loss could
                  vary according to the last refreshed at date of the source
                  data.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
