import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import "./productReport.css";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import { Tooltip as ReactTooltip } from "react-tooltip";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import Canvas from "../../helper/Screenshot/canvas";
import leftArrow from '../../assets/icons/left-arrow.svg'
import downloadIcon from "../../assets/svgs/download.svg";
import { createQueryURL, formatPieChartData , objectoFiltering,getSearchParamsObject,formatDate, getComparisonFormatTimeDimension } from "../../components/Reports/Components/utils";
import {useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomDropDown from '../../components/DropDown/CustomDropDown';
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import { Pagination } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { createComparisonCubeLoadResponse, createCubeLoadResponse } from "../../helper/widget";
import { ALL_WEEK_DAYS, defaultDimensions, getCubeFiltersByActionType, mergeCustomOutputToAPIFiltersFormat, removeRuleItemByMember, reverseMergeAPIFiltersToFilterModuleFormat, ruleItemInjector } from "../../helper/Quadrant/quadrantHelper";
import FilterWidget from "../../components/Filter/Widget";
import { FiltersAmazonDataQuadrant, FiltersDimensionDataQuadrant, FiltersMeasuresDataQuadrant } from "../../components/Filter/FilterData/quadrantFilter";
import StopLossFilters from "../StopLossList/subComponents/StopLossFilters/StopLossFilters";
import { processFields } from "../../components/Filter/subComponents/filterHelper";
import { DYNAMIC_SECTION_PAGES, PAGE_TYPE_CONSTANTS } from "../../constant/productAnalytics/productReportConfigs";
import MultiSelect from "../../components/DropDown/Multiselect";
import downArrow from "../../assets/icons/downArrowForSplitButton.svg";
import MasterTargetingSearchSelectModal from "../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import { fetchPaginatedData } from "../../helper/common/fetchPaginatedData";
import { masterApi } from "../../apis/masterApi";
import { encodeData } from "../../helper/common/encodeData";
import { generateFiltering } from "../../helper/CampaignList/masterAds/generateFiltering";
import ProductSetFilters from "../productSets/subComponents/ProductSetFilters";
import checkDuplicateStringInArray from "../../helper/common/checkDuplicateStringinArray";
import { PRODUCT_SET_REFRESH_DURATION } from "../../constant/productset/productsetconfig";
import useUserDetails from "../../helper/userData/useUserDetails";
import CustomPagination from "../../components/CustomPagination/CustomPagination";



const ProductReport = (props) => {
  const {
    calendarDate,
    setCalendarDate,
    dateDifference,
    setDateDifference,
    applyCalendar,
    multiCalendarDate,
    clientId,
  } = useGlobalContext();
  
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [tableView, setTableView] = useState("table");
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  let urlQuery = paramsObject?.urlQuery;
  let pslRuleId = paramsObject?.pslRuleId;
  let productsetId = paramsObject?.productsetId;
  let pageType = paramsObject?.page;
  const isStopLossPage = pageType === 'stoploss' ?? false;
  const isProductSetPage = pageType === 'productset' ?? false;

  const isPageUnderDynamicSection = DYNAMIC_SECTION_PAGES?.includes(pageType) ?? false
  const pageTypeConstantHeading = PAGE_TYPE_CONSTANTS[pageType]?.heading ?? '' 
  const viewTYPE =allParams.get('viewtype') ?? 'Performance View'
  const stopLossConfigRule = {
    name: '',
    recommend_only: false,
    frequency: "Daily",
    max_percent_exclude: 10,
    stoploss_running_days: ALL_WEEK_DAYS,
  }
  // decode the urlQuery and parse it into json
  // const previousFiltering = objectoFiltering(paramsObject,"VariantAnalysisTablePPMPlaceholder")
  const [currentViewType, setCurrentViewType] = useState(viewTYPE);
  const isComparisonView = currentViewType === 'Comparison View';

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const [searchParams,setSearchParams] = useSearchParams({});
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"VariantAnalysisTablePPMPlaceholder.obDate",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"VariantAnalysisTablePPMPlaceholder.obDate",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]

  const { clientAccountId } = useGlobalContext();

  const [childrenOne, setChildrenOne] = useState({});
  const [childDataIndex, setChildDataIndex] = useState(0);
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [summaryData, setSummaryData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [filterModuleFilters, setFilterModuleFilters] = useState({});
  const [inclusionFilterModuleFilters, setInclusionFilterModuleFilters] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isStoplossSaving, setIsStoplossSaving] = useState(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isPSLRuleFilterLoading, setIsPSLRuleFilterLoading] = useState(true);
  const groupByOptions = ["Product","Detailed category","Broad category"];
  const initialGroupByFilter = new URLSearchParams(window.location.search)?.get('reportType') ?? 'Product';
  const [groupByFilter,setGroupByFilter] = useState(initialGroupByFilter);
  const initialviewType = groupByFilter?.includes("Product") ? "Child Asin" : null 
  const [viewType, setViewType] = useState(initialviewType);
  const [stoplossRuleDetail, setStoplossRuleDetail] = useState(stopLossConfigRule);
  const [productStopLossRuleList, setProductStopLossRuleList] = useState([]);
  const [customDate, setCustomDate] = useState("last_7d")
  const [inclusionCustomDate, setInclusionCustomDate] = useState("last_7d")
  const [errors, setErrors] = useState({});

  // Only Product Set States ***** Start ******
  const initialConfigProductSetDetail = {
    name:'',
    is_dynamic:true,
    refresh_duration:'7 Days',
    product_set_type:'amazon_smart_product_set_v2',
    source:'amazon',
    de_duplication:false
  }
  const [selProductType, setSelProductType] = useState("Dynamic");
  const [selectedCampaigns,setSelectedCampaigns] = useState([]);
  const [allProductSetList,setAllProductSetList] = useState([]);
  const [userDetails] = useUserDetails()
  const [selectedAdGroups,setSelectedAdGroups] = useState([])
  const [productSetDetail,setProductSetDetail] = useState(initialConfigProductSetDetail);
  const [allFiltersVisible, setAllFilterVisible] = useState({
      mainFilterLogicModal: false,
      ConfirmAlertModal: false,
      searchSortModal: false,
    });
  const [searchFiltersState, setSearchFiltersState] = useState({
      portfolios: [],
      campaigns: [],
      adGroups: [],
      categories: [],
    });
  const [campaignsIds, setCampaignsIds] = useState("");
  const allProductSetName =   allProductSetList?.map((data) => {
    return data?.name
  }) ?? []
      
  

 
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTablePPMPlaceholder.cost",
    "VariantAnalysisTablePPMPlaceholder.costDRR",
    "VariantAnalysisTablePPMPlaceholder.SalePrice",
    "VariantAnalysisTablePPMPlaceholder.Quantity",
    "VariantAnalysisTablePPMPlaceholder.impressions",
    "VariantAnalysisTablePPMPlaceholder.impressionsDRR",
    "VariantAnalysisTablePPMPlaceholder.clicks",
    "VariantAnalysisTablePPMPlaceholder.clicksDRR",
    "VariantAnalysisTablePPMPlaceholder.ctr",
    "VariantAnalysisTablePPMPlaceholder.ctrDRR",
    "VariantAnalysisTablePPMPlaceholder.cpc",
    "VariantAnalysisTablePPMPlaceholder.cpcDRR",
    "VariantAnalysisTablePPMPlaceholder.cpm",
    "VariantAnalysisTablePPMPlaceholder.cpmDRR",
    "VariantAnalysisTablePPMPlaceholder.conversions14d",
    "VariantAnalysisTablePPMPlaceholder.conversions14dDRR",
    "VariantAnalysisTablePPMPlaceholder.conversion_rate",
    "VariantAnalysisTablePPMPlaceholder.conversion_rateDRR",
    "VariantAnalysisTablePPMPlaceholder.sales14d",
    "VariantAnalysisTablePPMPlaceholder.sales14dDRR",
    "VariantAnalysisTablePPMPlaceholder.average_order_value",
    "VariantAnalysisTablePPMPlaceholder.average_order_valueDRR",
    "VariantAnalysisTablePPMPlaceholder.acos",
    "VariantAnalysisTablePPMPlaceholder.acosDRR",
    "VariantAnalysisTablePPMPlaceholder.roas",
    "VariantAnalysisTablePPMPlaceholder.roasDRR",
    "VariantAnalysisTablePPMPlaceholder.MaximumRetailPrice",
    "VariantAnalysisTablePPMPlaceholder.BSR",
    "VariantAnalysisTablePPMPlaceholder.total_asp",
    "VariantAnalysisTablePPMPlaceholder.troas",
    "VariantAnalysisTablePPMPlaceholder.tacos",
    "VariantAnalysisTablePPMPlaceholder.trevnue",
    "VariantAnalysisTablePPMPlaceholder.tunits_sold",
  ]);

  
  const initialCubeQueryDimensions = groupByFilter?.includes("Broad") ? [
    "VariantAnalysisTablePPMPlaceholder.broadCategory",
  ] : groupByFilter?.includes("Detailed") ? [
    "VariantAnalysisTablePPMPlaceholder.category",
  ] : [
    "VariantAnalysisTablePPMPlaceholder.asin",
    "VariantAnalysisTablePPMPlaceholder.productNameWithImage",
  ]

  if(isProductSetPage) {
    initialCubeQueryDimensions?.push("VariantAnalysisTablePPMPlaceholder.sku") // Only 
  }

  const isSubstringPresent = (string,subStr) => {
    return string?.includes(subStr)
   }
  
 
   const initialChildDimensions = isSubstringPresent(groupByFilter,"Broad") ? [
     "VariantAnalysisTablePPMPlaceholder.broadCategory",
     "VariantAnalysisTablePPMPlaceholder.category"
   ] : isSubstringPresent(groupByFilter,"Product") ? ["VariantAnalysisTablePPMPlaceholder.asin",
   "VariantAnalysisTablePPMPlaceholder.productNameWithImage"] : [
     "VariantAnalysisTablePPMPlaceholder.category",
   ]

   const excludeSBCampaignsFiltering = {type: "RULE_ITEM","member":"VariantAnalysisTablePPMPlaceholder.campaignFormat","operator":"notEquals","values":["SB","SB(V)"]}
  // const initialCubeQueryFilters = (isSubstringPresent(groupByFilter,"Broad") || isSubstringPresent(groupByFilter,"Detailed")) ? [{
  //   member: "VariantAnalysisTablePPMPlaceholder.profileId",
  //   operator: "equals",
  //   values: [clientAccountId],
  // },...previousFiltering,excludeSBCampaignsFiltering] : [
  //   {
  //     and: [
  //       {
  //         member: "VariantAnalysisTablePPMPlaceholder.profileId",
  //         operator: "equals",
  //         values: [clientAccountId],
  //       },
  //       ...previousFiltering,
  //       excludeSBCampaignsFiltering
  //     ],
  //   },
  // ]

  const initialChartFilters = isSubstringPresent(groupByFilter,"Broad") ? {
    spend: "cost",
    dimension:"broadCategory",
    revenue:"sales14d"
  } : isSubstringPresent(groupByFilter,"Detailed") ? {
    spend: "cost",
    dimension:"category",
    revenue:"sales14d"
  } : {
    spend: "cost",
    dimension:"asin",
    revenue:"sales14d"
  }

  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["VariantAnalysisTablePPMPlaceholder.cost", "desc"],
  ]);

  const [cubeChildRowQueryOrder, setCubeChildRowQueryOrder] = useState([
    ["VariantAnalysisTablePPMPlaceholder.cost", "desc"],
  ]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState(initialCubeQueryDimensions);

  const [cubeChildRowsQueryDimensions, setCubeChildRowsQueryDimensions] =
    useState(initialChildDimensions);

  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  // const [cubeQueryFilters, setCubeQueryFilters] = useState(initialCubeQueryFilters);
  // const [cubeChildRowsQueryFilters, setCubeChildRowsQueryFilters] = useState(initialCubeQueryFilters);
  const [cubeChildExpendableRuleItems, setCubeChildExpendableRuleItems] = useState(
    // SAMPLE DATA FORMAT
    [
    //   {
    //     type: "RULE_ITEM",
    //     member: "VariantAnalysisTablePPMPlaceholder.parentSku",
    //     operator: "equals",
    //     values: ["BDISO284N2"],
    //   },
    ]
  );

  const [resultSet, setResultSet] = useState({});
  const [chartFilters, setChartFilters] = useState(initialChartFilters);
  var spendResultSet = formatPieChartData(
    resultSet,
    chartFilters.spend,
    "VariantAnalysisTablePPMPlaceholder",
    chartFilters.dimension
  );
  var revenueResultSet = formatPieChartData(
    resultSet,
    chartFilters.revenue,
    "VariantAnalysisTablePPMPlaceholder",
    chartFilters.dimension
  );

  const [drrView, setDrrView] = useState("");

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

 // Only Product Set Functions *** Start ***


function mergeCampaignsAndAdgroups(campaignData,adgroupData) {
  const mergedData = adgroupData?.reduce((acc,curr) => {
    const foundedCampaign = campaignData?.find((data) => data?.id === curr?.campaign_id)
    if(foundedCampaign) {
      acc.push({name:`${curr?.name} (Camp. Name:- ${foundedCampaign?.name})`,adgroup_id:curr?.id,campaign_name:foundedCampaign?.name,campaign_id:foundedCampaign?.id})
    }
    return acc
  },[])
  return mergedData
}

function getMergedDataOptionsForDropDown(selectedCampaigns,overAllMergedData) {
 return overAllMergedData?.reduce((acc,curr) => {
    if(selectedCampaigns?.length) {
      const foundedAdgroup = selectedCampaigns?.find((campaign) => campaign === curr?.campaign_name)
      if(foundedAdgroup) {
        acc.push(curr.name)
      }
    } else {
      acc.push(curr.name)
    } 
    return acc
  },[]) 
}

 const allCampaignsOptions = useMemo(() => {
    return searchFiltersState?.campaigns?.map((data) => {
      return data?.name
    }) ?? []
  },[searchFiltersState])

  const mergedData = useMemo(() => {
      return mergeCampaignsAndAdgroups(searchFiltersState?.campaigns ?? [],searchFiltersState?.adGroups ?? [])
    },[searchFiltersState]) 

  const mergedDataOptionsForDropDown =  useMemo(() => {
      return getMergedDataOptionsForDropDown(selectedCampaigns,mergedData)
    },[mergedData,selectedCampaigns])

  const renderCampaignLevelItem = (item) => {
    const foundedCampaign =
      searchFiltersState?.campaigns?.find((data) => data?.name === item) ?? {};
    const isActive = foundedCampaign?.status?.toLowerCase() === "enabled";

    return (
      <div className="m-0 gap-1 d-flex justify-content-between">
        <p className="m-0">{item} </p>
        <div className="mt-status-dot-container">
          <div
            className={`${isActive ? "enabled" : "paused"}-status-dots-mt`}
          ></div>
        </div>
      </div>
    );
  };


  function handleProductSetDetail (key,value) {
    setProductSetDetail((prev) => {
      const data = {...prev}
      if(key === 'name') {
        const isAlreadyExists =  checkDuplicateStringInArray(productSetDetail?.name,allProductSetName)
        if(isAlreadyExists) {
          data[key] = value;
          return data
        } else {
          data[key] = value
          setErrors({...errors,'name':''})
        }
      }
      data[key] = value
      return data
    })
  }

  const validateProductSetConfiguration = () => {
    const invalidProductSetName = productSetDetail?.name?.trim() == '' || productSetDetail?.name == undefined || productSetDetail?.name == null
    const isAlreadyExists =  checkDuplicateStringInArray(productSetDetail?.name,allProductSetName)
     if (invalidProductSetName) {
        setErrors((prev) => ({ ...prev, "name": "Please give valid Name." }));
        toast.error("Please enter product set name.");
        return false;
    } else if (!invalidProductSetName && isAlreadyExists && !productsetId) {
      setErrors((prev) => ({ ...prev, "name": "Product set Name already exists." }));
      toast.error("Product set name already exists. Please try a different one.");
      return false
    }
    if(selectedCampaigns?.length > 0 && selectedAdGroups?.length === 0) {
      toast.error('Please select adgroups as well or remove campaigns')
      return false
    }
    return true
  }

  function formatDataForProductSet ()  {
    let formattedData = {};
    const exclusionQueryFilter = getUpdatedMappedFilter(filterModuleFilters,"exclusion");
    if(exclusionQueryFilter?.query?.dimensions?.length) {
      exclusionQueryFilter.query.dimensions.push("VariantAnalysisTablePPMPlaceholder.sku")
    }
    const date_duration_refresh = `${productSetDetail?.refresh_duration?.match(
      /\d+/g
    )}_days`;
    const selectedCampaignsAndAdgroups = selectedAdGroups?.reduce((acc,curr) => {
      const foundedId = mergedData?.find((data) => data?.name === curr)
      if(foundedId) {
        acc.push({campaign_id: foundedId?.campaign_id, adset_id: foundedId?.adgroup_id})
      }
      return acc
  },[])
    if(!exclusionQueryFilter) {
      toast.error('Please apply filters before creating product set.')
      return false
    }
    formattedData = {
      name:productSetDetail?.name?.trim(),
      source:'amazon',
      product_set_type:'amazon_smart_product_set_v2',
      is_dynamic:true,
      refresh_duration:date_duration_refresh,
      cube_filters:JSON.stringify(exclusionQueryFilter),
      entity_populate_list:selectedCampaignsAndAdgroups,
      de_duplication:productSetDetail?.de_duplication ?? false,
      modified_by:userDetails?.user?.email,
    }
    return formattedData
  }

  async  function handleSaveProductSet () {
    const isValid = validateProductSetConfiguration();
    if (!isValid) {
      return;
    }
    const formattedData = formatDataForProductSet();
    if(typeof formattedData === 'boolean') {
      return;
    }
    try {
      let data = {};
      const url = productsetId ? `${process.env.REACT_APP_ADS_API}/client/${clientId}/product-set/${productsetId}?source=amazon` : `${process.env.REACT_APP_ADS_API}/client/${clientId}/product-set`;
      setIsStoplossSaving(true);
      setInitialLoad(true)
      if(productsetId) {
        data = await axios.patch(url,formattedData,{headers:{
          'Content-Type':'application/merge-patch+json'
        }})
      } else {
        data = await axios.post(url,formattedData)
      }
      if (data.status === 200 && !productsetId) {
        toast.success("Product set created successfully!")
         navigate('/product-set-list');
      } else if (data.data?.success && productsetId) {
        toast.success("Product set updated successfully!")
         navigate('/product-set-list');
      } else {
          toast.error("Error while saving product set. Try Again!")
      }
      setIsStoplossSaving(false);
      setInitialLoad(false)
    } catch (error) {
      setIsStoplossSaving(false);
      setInitialLoad(false)
      const message = error?.response?.status!==200 ? error?.response?.data?.message : error?.message
      toast.error(message ?? 'Some unknown error occurred!')
    }
  }

  async function fetchProductSetList() {
    try {
      setInitialLoad(true)
      const urlForAllProductSets = `${process.env.REACT_APP_ADS_API}/client/${clientId}/product-set?source=amazon&categories`
      const allProductSets = await axios.get(urlForAllProductSets)
      const productSetData = allProductSets?.data ?? []
      setAllProductSetList(productSetData ?? []);
      if(productSetData?.length && productsetId) {
        const foundedProductSet = productSetData?.find((productSet) => {
          return productSet?.id?.toLowerCase() === productsetId
        }) ?? {}
        foundedProductSet['refresh_duration'] = PRODUCT_SET_REFRESH_DURATION[foundedProductSet['refresh_duration']] ?? '7 Days'
        setProductSetDetail(foundedProductSet ?? {});
        let apiFilter = {};
        const parsedQuery = JSON.parse(foundedProductSet?.cube_filters ?? '{}')
        let filterCustomFormatExclusion = reverseMergeAPIFiltersToFilterModuleFormat(parsedQuery?.query?.filters);
         let updatedDimension = removeRuleItemByMember(filterCustomFormatExclusion?.dimension,"VariantAnalysisTablePPMPlaceholder.profileId");
         let updatedAmazonCampaign = removeRuleItemByMember(filterCustomFormatExclusion?.campaignAmazon,"VariantAnalysisTablePPMPlaceholder.campaignFormat");
         filterCustomFormatExclusion.dimension = updatedDimension;
          filterCustomFormatExclusion.campaignAmazon = updatedAmazonCampaign;
          apiFilter = filterCustomFormatExclusion;
         setFilterModuleFilters(filterCustomFormatExclusion);
         handleDataFetch(apiFilter)
      }
      setInitialLoad(false)
    } catch (error) {
      setInitialLoad(false)
      console.log(error);
      toast.error(error?.message ?? 'some unknown error occurred while fetching product set')
    }
  }

  const getPreselectedCampaignsAndAdGroups = (configData) => {
    const {entity_list,allCampaigns,allAdgroups} = configData
    const {adgroups,campaigns} = entity_list?.reduce((acc,curr) => { 
      const foundedCampaign = allCampaigns.find((campaign) => campaign?.id === curr?.campaign_id)
      const foundedAdGroup = allAdgroups?.find((adgroup) => adgroup?.adgroup_id === curr?.adset_id)
      if(foundedCampaign) {
        acc.campaigns.push(foundedCampaign?.name)
      }
      if(foundedAdGroup) {
        acc.adgroups.push(foundedAdGroup?.name)
      }
      return acc
    },{adgroups:[],campaigns:[]})
    return {campaigns,adgroups}
  }
 

  const getAllCampaigns = async () => {
      const adGroupFilteringBasedonCampaign = generateFiltering({
        campaign_id: campaignsIds ?? [],
      });
      const dataForCampaign = {
        source: "amazon",
        fields: "id,name,platform_id,status",
        limit:5000,
        filtering: [],
      };
      const dataForAdgroup = {
        source: "amazon",
        fields: "id,name,platform_id,campaign_id",
        limit:5000,
        filtering: encodeData(adGroupFilteringBasedonCampaign),
      };
      try {
        //  Call all the APIs concurrently to reduce the response time.
        const [campaignsResponse, adgroupResponse] = await Promise.all([
          fetchPaginatedData(masterApi, dataForCampaign, 5000, 3, "campaignList", false),
          fetchPaginatedData(masterApi, dataForAdgroup, 5000, 12, "adsetList", false)
        ]);
     
        const campaigns = campaignsResponse?.filter((campaign) => {
          return (campaign?.hasOwnProperty("platform_id") && campaign?.platform_id!==null)
        })
        const adgroups = adgroupResponse?.filter((adgroup) => {
          return (adgroup?.hasOwnProperty("platform_id") && adgroup?.platform_id!==null)
        })
        setSearchFiltersState({
          ...searchFiltersState,
          campaigns: campaigns,
          adGroups: adgroups,
        });
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      if(isProductSetPage) {
        getAllCampaigns();
        fetchProductSetList();
        setIsPSLRuleFilterLoading(false);
      }
    },[])

     useEffect(() => {
        if(productsetId && productSetDetail?.entity_populate_list?.length > 0 && searchFiltersState?.campaigns?.length > 0 && searchFiltersState?.adGroups?.length > 0) {
          const configData = {
            entity_list: productSetDetail?.entity_populate_list,
            allCampaigns: searchFiltersState?.campaigns,
            allAdgroups: mergedData
          }
          const {campaigns,adgroups} = getPreselectedCampaignsAndAdGroups(configData)
          const filteredCampaigns = [...new Set(campaigns)];
          setSelectedCampaigns([...filteredCampaigns])
          setSelectedAdGroups([...adgroups])
        }
      },[searchFiltersState,allProductSetList])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("start") && queryParams.get("end")) {
      setDate(queryParams.get("start"), queryParams.get("end"));
    }
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  const handleFilterOutput = (filters, type) => {
    setCurrentPage(1);
    if(filters?.dimension?.data?.length === 0 && filters?.metrics?.data?.length === 0 && filters?.campaignAmazon?.data?.length === 0){
      setIsFilterApplied(false);
    }else{
      setIsFilterApplied(true);
    }
    handleDataFetch(filters);
    setFilterModuleFilters(filters);
  };

  const handleInclusionFilterOutput = (filters, type) => {
    setInclusionFilterModuleFilters(filters);
  };


  useEffect(() => {
    if (currentViewType !== "Comparison View") {
      const startDate = formatDate(calendarDate[0].startDate);
      const endDate = formatDate(calendarDate[0].endDate);
      if (
        startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] ||
        endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["dateRange"] = [startDate, endDate];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  useEffect(() => {
    if (currentViewType === "Comparison View") {
      let startDate = formatDate(multiCalendarDate.selection1.startDate);
      let endDate = formatDate(multiCalendarDate.selection1.endDate);
      let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
      let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
      let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"];
      if (
        startDate !== apiDateRange[0][0] ||
        endDate !== apiDateRange[0][1] ||
        startDatePrevious !== apiDateRange[1][0] ||
        endDatePrevious !== apiDateRange[1][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["compareDateRange"] = [
            [startDate, endDate],
            [startDatePrevious, endDatePrevious],
          ];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  function checkSBFiltering (filtersData) {
   return filtersData.find((data) => data?.member === "VariantAnalysisTablePPMPlaceholder.campaignFormat" && data?.operator === 'notEquals')
  }

  async function getProductReportData(filter,comparisonApi) {
    try {
      setIsLoaderVisible(true);
      let url = "";
      let SummaryUrl = "";
     if(comparisonApi){
       url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison?source=amazon`;
       SummaryUrl = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison-summary?source=amazon`;
    }else{
       url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights?source=amazon`;
       SummaryUrl = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights-summary?source=amazon`;
      }

      let tempExtraFilters = filter?.campaignAmazon;
      let extraFilters = {
        type: "amazon",
        amazon_campaigns_filter: tempExtraFilters,
      };
      let cubeDimesions = cubeQueryDimensions;
      // if(isProductSetPage  && comparisonApi) {
      //   cubeDimesions = cubeQueryDimensions?.filter((dimension) => {
      //     return !dimension?.includes('.sku') ?? true
      //   })
      // } // Query Dimension For Change
      const queryData = {
        dimensions: cubeDimesions,
        filters: filter,
        extra_filters: extraFilters,
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        date_preset: isPageUnderDynamicSection ? customDate : null,
        timeDimensions: comparisonApi?getComparisonFormatTimeDimension(cubeQueryTimeDimensions):cubeQueryTimeDimensions,
        offset: (currentPage - 1) * itemsPerPage,
        limit:itemsPerPage,
      };
      const dataToPost = {
        query: queryData,
      };
      const summaryData = await axios.post(SummaryUrl, dataToPost);
      if(summaryData?.status === 200){
        var totalCount = summaryData?.data?.total_count;
        setSummaryData(summaryData?.data);
      }
      const data = await axios.post(url, dataToPost);
      if (data?.status === 200) {
        let parsedCubeFormatData = {};
        if(comparisonApi){
          parsedCubeFormatData = createComparisonCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeQueryDimensions,order:cubeQueryOrder,filters:filter, data:data?.data?.data,total:totalCount,summary:summaryData?.data,timeDimensions:cubeQueryTimeDimensions,dataToPost:dataToPost});
        }else{
          parsedCubeFormatData = createCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeQueryDimensions,order:cubeQueryOrder,filters:filter, data:data?.data?.data,total:totalCount,summary:summaryData?.data,timeDimensions:cubeQueryTimeDimensions,dataToPost:dataToPost});
        }
        return parsedCubeFormatData;
      }
    } catch (error) {
      toast.error("unknown error occurred" + JSON.stringify(error));
      console.log(error);
    }finally {
      setIsLoaderVisible(false);
    }
  }

  const handleDataFetch = (moduleFilters) => {
    const filters = mergeCustomOutputToAPIFiltersFormat(
      moduleFilters?.metrics?.custom_output,
      moduleFilters?.dimension?.custom_output,
      moduleFilters?.campaignAmazon?.custom_output,
    );
    
    let tempFilter = JSON.parse(JSON.stringify(filters));
    let updatedFiltersWithDimensions = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId));
    let updatedFilters = ruleItemInjector(updatedFiltersWithDimensions,"campaignAmazon", defaultDimensions("",[excludeSBCampaignsFiltering]));
    setIsLoaderVisible(true);
    // setInitialLoad(true);

      getProductReportData(updatedFilters,currentViewType === "Comparison View").then((resultSet) => {
      setResultSet((prev)=>{
        return resultSet;
      });
      setIsLoaderVisible(false);
      // setInitialLoad(false);
      setTotalPages(Math.ceil(resultSet?.loadResponses?.[0]?.total / itemsPerPage) ?? 1);
      if (currentViewType === "Comparison View") {
        setTableView("ComparisonTable");
      } else {
        setTableView("table");
      }
    });
  };

  useEffect(() => {
    if(urlQuery){
      handleQueryChange(urlQuery);
    }else if(pageType === "stoploss" || isProductSetPage){
      if((pslRuleId || productsetId) && isPSLRuleFilterLoading){
        return;
      }else{
        handleDataFetch(filterModuleFilters);
      }
    } else {
      handleDataFetch(filterModuleFilters)
    }

  }, [
    cubeQueryMeasure,
    cubeQueryDimensions,
    cubeQueryTimeDimensions,
    cubeToken,
    cubeQueryOrder,
    urlQuery,
    currentPage
  ]);

  const expendChange = (data, type, index) => {
    if(groupByFilter === "Product") {
      if (type === "campaign_format_child" || type === "link") {
        let url = "/master-ads?asinId=" + data;
        window.open(url);
      } else {
        if(groupByFilter === "Product") {

          setCubeChildExpendableRuleItems(
            [
              {
                type: "RULE_ITEM",
                member: "VariantAnalysisTablePPMPlaceholder.parentSku",
                operator: "equals",
                values: [data],
              },
            ]
          )
        } 
        // setCubeChildRowsQueryFilters(prevData);
        setChildDataIndex(data);
      }
    } else if (groupByFilter?.includes("Broad")) {
      setChildDataIndex(data);
      setCubeChildExpendableRuleItems(
        [
          {
            type: "RULE_ITEM",
            member: "VariantAnalysisTablePPMPlaceholder.broadCategory",
            operator: "equals",
            values: [data],
          },
        ]
      )
      
    } else if(groupByFilter?.includes("Detailed")) {
      let url = "/product-analytics";
      const filters = mergeCustomOutputToAPIFiltersFormat(
        filterModuleFilters?.metrics?.custom_output,
        filterModuleFilters?.dimension?.custom_output,
        filterModuleFilters?.campaignAmazon?.custom_output,
      );
      let categoryRule =[

            {
              type: "RULE_ITEM",
              member: "VariantAnalysisTablePPMPlaceholder.category",
              operator: "equals",
              values: [data],
            },
    ]
    let updatedFiltersWithDimensions = ruleItemInjector(filters,"dimensions", defaultDimensions("",categoryRule));

    const queryParams = [
      ["start", formatDate(calendarDate[0].startDate)],
      ["end", formatDate(calendarDate[0].endDate)],
      ["viewtype",currentViewType]
    ];
    const queryString = queryParams
      .filter(([key, value]) => value !== "All")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    url += `?${queryString}&urlQuery=${encodeURIComponent(JSON.stringify(updatedFiltersWithDimensions))}`;
    // Open URL in new tab

    window.open(url);
    }
  };
  const validateAlphanumeric = (input) => {
    const alphanumericRegex = /^[a-zA-Z0-9_\s]*$/;// /^[a-zA-Z0-9_\/\s]*$/;
    return alphanumericRegex.test(input);
  }
  const handleCustomDate = (option) => {
    setCustomDate(option.value)
  }

const handleInclusionCustomDate = (option) => {
    setInclusionCustomDate(option.value)
}

  const handleStoplossRuleDetail = (key, value, include = true) => {
    setStoplossRuleDetail((prev) => {
        let data = { ...prev }
        if (key == 'frequency') {
            if (value == 'Weekly') {
                data['stoploss_running_days'] = ['monday'];
            } else if (value == 'Biweekly') {
                data['stoploss_running_days'] = ['monday', 'thursday'];
            }else if(value=='Daily'){
                data['stoploss_running_days'] = ALL_WEEK_DAYS;                 
            }
            data[key] = value;
            return data;

        }
        else if (key == 'stoploss_running_days') {
            let stopLossDays = data['stoploss_running_days'];
            const index = stopLossDays.indexOf(value);

            if (index !== -1) {
                stopLossDays.splice(index, 1);
            } else {
                if (data['frequency'] == 'Biweekly' && stopLossDays.length >= 2) {
                    stopLossDays[1] = value;
                } else if (data['frequency'] == 'Weekly') {
                    stopLossDays[0] = value;
                } else {
                    stopLossDays.push(value);
                }
            }

            data['stoploss_running_days'] = stopLossDays;
            return data;
            

        } else if (key === 'name') {
            const nameExistAlready = productStopLossRuleList.includes(value?.trim());
            
            if (!validateAlphanumeric(value) || value.startsWith(' ')) {
                setErrors((prev) => ({ ...prev, "name": "Only alphabet, number and underscore are allowed." }));
                return data;
            } else if (nameExistAlready && !pslRuleId) {
                setErrors((prev) => ({ ...prev, "name": "Rule Name Already Exists!" }));
                data[key] = value;
                return data;
            } else {
                setErrors((prev) => ({ ...prev, "name": '' }));
                data[key] = value;
                return data;
            }
        } else if (key=='max_percent_exclude'){
                setErrors((prev) => ({ ...prev, "max_percent_exclude": '' }));
                data[key] = value;
                return data;
        }
        else {
            data[key] = value;
            return data;
        }
    })
}

const validateStoplossRuleConfiguration = () => {
  for (const key in errors) {
      if (errors[key]) {
          toast.error("Please resolve all the errors." );       
          return false; // Found a truthy value, return false
      }
    }     
   if (stoplossRuleDetail.name?.trim() == '' || stoplossRuleDetail.name == undefined || stoplossRuleDetail.name == null) {
      setErrors((prev) => ({ ...prev, "name": "Please give valid Rule Name." }));
      toast.error("Enter valid Rule Name.");
      return false;
  } else if (isNaN(stoplossRuleDetail.max_percent_exclude)) {
      toast.error("Please select Exclusion limit.");
      setErrors((prev) => ({ ...prev, "max_percent_exclude": " " }));
      return false;

  }else {
      return true
  }
}

const getUpdatedMappedFilter = (filterModule,filterType="") => {
  const filters = mergeCustomOutputToAPIFiltersFormat(
    filterModule?.metrics?.custom_output,
    filterModule?.dimension?.custom_output,
    filterModule?.campaignAmazon?.custom_output,
  );
  let tempFilter = JSON.parse(JSON.stringify(filters));
  // exclusion Validation
  if(!Object.keys(tempFilter)?.length>0){
    return false;
  }

  let updatedFiltersWithDimensions = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId,cubeChildExpendableRuleItems));
  let updatedFilters = ruleItemInjector(updatedFiltersWithDimensions,"campaignAmazon", defaultDimensions("",[excludeSBCampaignsFiltering]));
  let tempExtraFilters = updatedFilters?.campaignAmazon;
      let extraFilters = {
        type: "amazon",
        amazon_campaigns_filter: tempExtraFilters,
      };
      const queryData = {
        dimensions: cubeQueryDimensions,
        filters: updatedFilters,
        extra_filters: extraFilters,
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        date_preset: filterType === "exclusion" ? customDate : inclusionCustomDate,
        timeDimensions:cubeQueryTimeDimensions,
        limit:1000,
      };
      const dataToPost = {
        query: queryData,
      };
      return dataToPost;
}

const formatStoplossRuleConfiguration = () => {
  const cubeTimePeriodMapper={
    "LAST_3_DAY":"last_3d",
    "LAST_7_DAY":"last_7d",
    "LAST_30_DAY":"last_30d"
}
  let formattedData ={};
  
  var exclusionQueryFilter = getUpdatedMappedFilter(filterModuleFilters,"exclusion");
  var inclusionQueryFilter = getUpdatedMappedFilter(inclusionFilterModuleFilters,"inclusion");
  if(!(exclusionQueryFilter || inclusionQueryFilter)){
    toast.error("Please apply exclusion/inclusion filters to create Stop-loss Rule.");
    return false;
  }
const exclusionTimePeriod = Object.entries(cubeTimePeriodMapper).map(([key, val]) => val === customDate ? key : null).filter(key => key !== null);
const inclusionTimePeriod = Object.entries(cubeTimePeriodMapper).map(([key, val]) => val === inclusionCustomDate ? key : null).filter(key => key !== null);


  let ruleTasks =[];
  if(exclusionQueryFilter) {
      ruleTasks.push({
        "entity_metric_filter":
        {
            "timePeriod": exclusionTimePeriod.length ? exclusionTimePeriod[0] : "",
            "type": "CUBE_BASED_NESTED_AND_OR", 
            "filters": [],
            "cube_filters": JSON.stringify(exclusionQueryFilter)
        }, 
        "rule_action_config":
         { 
            "entity_action_config": { "action_type": "PAUSE_PRODUCTS" }, 
            "max_percent_exclude": stoplossRuleDetail.max_percent_exclude 
        }
    });
  }
  if(inclusionQueryFilter) {
      ruleTasks.push({
          "entity_metric_filter":
          {
              "timePeriod": inclusionTimePeriod.length ? inclusionTimePeriod[0] : "",
              "type": "CUBE_BASED_NESTED_AND_OR", 
              "filters": [],
              "cube_filters": JSON.stringify(inclusionQueryFilter)
          }, 
          "rule_action_config":
           { 
              "entity_action_config": { "action_type": "ENABLE_PRODUCTS"}, 
          }
      })
  }
      var ruleScheduleConfig = {
      'day_frequency': stoplossRuleDetail?.frequency,
        'frequency_values': stoplossRuleDetail?.stoploss_running_days
        }

  formattedData ={
      client_id : clientId,
      type:"AMAZON_STOP_LOSS",
      name: stoplossRuleDetail?.name?.trim(),
      modified_by: localStorage.getItem('email'),
      status:'Active',
      rule_objective:"Stop Loss",
      apply_decision : !stoplossRuleDetail?.recommend_only,
      rule_tasks: ruleTasks,
      schedule_config: ruleScheduleConfig
  }

  return formattedData
}

async function handleSaveStoplossRuleFunction() {
  const isValid = validateStoplossRuleConfiguration();
  if (!isValid) {
      return;
  }
  let formattedData = formatStoplossRuleConfiguration();
  if(typeof formattedData === 'boolean'){
    return;
  }
      let data = {};
      setIsStoplossSaving(true);
      try {
        if(pslRuleId){
          data = await axios.patch(`${process.env.REACT_APP_ADS_API}/client/${clientId}/automation-rule-set/${pslRuleId}?source=amazon`, formattedData);
       }else{
          data = await axios.post(`${process.env.REACT_APP_ADS_API}/client/${clientId}/automation-rule-set?source=amazon`, formattedData);
       }
       if (data.data?.id) {
          toast.success("Stoploss Rule created successfully!")
           navigate('/stop-loss-list');
       } else if (data.data?.success) {
          toast.success("Stoploss Rule updated successfully!")
           navigate('/stop-loss-list');
     } else {
          toast.error("Error while saving Stoploss Rule. Try Again!")
     }
      } catch (error) {
        const message = error?.response?.status!==200 ? error?.response?.data?.message : error?.message
        toast.error(message ?? 'Some unknown error occurred!')
      }
    
    setIsStoplossSaving(false);
  }


  async function getChildProductReportData(filter,comparisonApi) {
    try {
      let url = "";
      if(comparisonApi){
        url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison?source=amazon`;
      }else{
         url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights?source=amazon`;
        }
      let tempExtraFilters = filter?.campaignAmazon;
      let extraFilters = {
        type: "amazon",
        amazon_campaigns_filter: tempExtraFilters,
      };

      const queryData = {
        dimensions: cubeChildRowsQueryDimensions,
        filters: filter,
        extra_filters: extraFilters,
        measures: cubeQueryMeasure,
        order: cubeChildRowQueryOrder,
        timeDimensions: comparisonApi?getComparisonFormatTimeDimension(cubeQueryTimeDimensions):cubeQueryTimeDimensions,
        limit:10000,
      };
      const dataToPost = {
        query: queryData,
      };
      const data = await axios.post(url, dataToPost);
      if (data?.status === 200) {
        let parsedCubeFormatData = {};
        if(comparisonApi){
          parsedCubeFormatData = createComparisonCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeChildRowsQueryDimensions,order:cubeChildRowQueryOrder,filters:filter, data:data?.data?.data,timeDimensions:getComparisonFormatTimeDimension(cubeQueryTimeDimensions)});
        }else{
          parsedCubeFormatData = createCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeChildRowsQueryDimensions,order:cubeChildRowQueryOrder,filters:filter, data:data?.data?.data,timeDimensions:cubeQueryTimeDimensions});
        }
        return parsedCubeFormatData;
      }
    } catch (error) {
      toast.error("unknown error occurred" + JSON.stringify(error));
      console.log(error);
    }finally {
      setIsLoaderVisible(false);
        // setInitialLoad(false);
    }
  }



  useEffect(() => {
    let getData = false;
    if(groupByFilter?.includes("Product")) {
      if(cubeChildExpendableRuleItems?.length > 0){
        getData = true;
      }
    } else if (groupByFilter?.includes("Broad")) {
      if(cubeChildExpendableRuleItems?.length > 0){
        getData = true;
      }
    }
    // to fetch child rows data

    if (getData) {
      const filters = mergeCustomOutputToAPIFiltersFormat(
        filterModuleFilters?.metrics?.custom_output,
        filterModuleFilters?.dimension?.custom_output,
        filterModuleFilters?.campaignAmazon?.custom_output,
      );
      let tempFilter = JSON.parse(JSON.stringify(filters));
      let updatedFiltersWithDimensions = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId,cubeChildExpendableRuleItems));
      let updatedFilters = ruleItemInjector(updatedFiltersWithDimensions,"campaignAmazon", defaultDimensions("",[excludeSBCampaignsFiltering]));

      getChildProductReportData(updatedFilters,currentViewType === "Comparison View").then((resultSet) => {
        setChildrenOne((prev) => {
          return { ...prev, [childDataIndex]: resultSet };
        });
        setIsLoaderVisible(false);
        // setInitialLoad(false);
      });
    }
  }, [cubeChildExpendableRuleItems]);

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);


  const onDropdownImghandler = (type) => {
    // console.log("@$# onDropdownImghandler");
  };

  useEffect(() => {
    if(viewType!==null) {
      setIsLoaderVisible(true);
      // setResultSet({})
      // TODO: fix and recheck this logic 
      // let prevData = [...cubeQueryFilters];
      // prevData[0]["and"].forEach((x, i) => {
      //   if (x.operator === "contains") {
      //     prevData[0]["and"].splice(i, 1);
      //   }
      // });
      // let is_same_prev =
      //   cubeQueryFilters.length == prevData.length &&
      //   cubeQueryFilters.every(function (element, index) {
      //     return element === prevData[index];
      //   });
      // if (!is_same_prev) {
      //   setCubeQueryFilters(prevData);
      // }
      if (viewType === "Parent Asin") {
        let newCubeDimention = ["VariantAnalysisTablePPMPlaceholder.parentSku"];
        let is_same =
          cubeQueryDimensions.length == newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      } else {
        let newCubeDimention = [
          "VariantAnalysisTablePPMPlaceholder.asin",
          "VariantAnalysisTablePPMPlaceholder.productNameWithImage",
        ];
        if(isProductSetPage) {
          newCubeDimention.push("VariantAnalysisTablePPMPlaceholder.sku")
        }
        let is_same =
          cubeQueryDimensions.length === newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      }
      setChartFilters((prev) => {
        prev.dimension = viewType === "Parent Asin" ? "parentSku" : "asin";
        return prev;
      });
    }
  }, [viewType]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    if(groupByFilter) {
      const queryParams = new URLSearchParams(window.location.search)
     queryParams?.set("reportType",groupByFilter)
     queryParams?.set("viewtype",currentViewType)
     const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
     window.history.replaceState(null, null, newUrl);
    }
  },[groupByFilter,currentViewType])

  const handleComparisonView = (type) => {
    setChildrenOne({});
    if (type === "Comparison View") {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTablePPMPlaceholder.obDate",
          compareDateRange: [
            [
              startDate,
              endDate,
            ],
            [
              startDatePrevious,
              endDatePrevious,
            ],
          ],
        },
      ]);
      setIsLoaderVisible(true);
    } else if (
      type !== "Comparison View" &&
      currentViewType === "Comparison View"
    ) {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTablePPMPlaceholder.obDate",
          dateRange: [
            formatDate(calendarDate[0].startDate),
            formatDate(calendarDate[0].endDate),
          ],
        },
      ]);
      setIsLoaderVisible(true);
    }
    setCurrentViewType(type);
  };




  // const handlePagination = async (type) => {
  //   setCurrentPage((prevPage) => {
  //     if (type === "next" && prevPage < totalPages) {
  //       return prevPage + 1;
  //     } else if (type === "prev" && prevPage > 1) {
  //       return prevPage - 1;
  //     }
  //     return prevPage; // No change if trying to go out of bounds
  //   });
  // };
  const handlePageChange = (pageNumber) => {
    // Simulate an API call
    setCurrentPage(pageNumber);
  };


  const sortData = (key, order) => {
    setCurrentPage(1)
    setCubeQueryOrder([
      [key, order]
    ]);
  }

  const handleDownload = () => {
    Canvas({ name: "product-analytics" });
  };

  const handleDrrView = (value, type) => {
    setDrrView(value);
    setChildrenOne({});
    if (value !== "drr") {
      setChartFilters((prev) => {
        prev.spend = "cost";
        prev.revenue = "sales14d";
        return prev;
      });
    } else {
      setChartFilters((prev) => {
        prev.spend = "costDRR";
        prev.revenue = "sales14dDRR";
        return prev;
      });
    }
  };

  function filterOutFilters (type,data) {
    // Filter out existing filters from the current states
   const mainData = type === "Product" ? [...data][0]["and"]?.filter((mainData,index) => {
      return mainData.member !== "VariantAnalysisTablePPMPlaceholder.parentSku"
   }).concat([...data][1]?.hasOwnProperty(["and"]) ? [...data][1]["and"] : [] ) : type?.includes("Broad") ? [...data]?.filter((mainData,mainIndex) => {
      return mainData.member !== "VariantAnalysisTablePPMPlaceholder.broadCategory"
   }) : data
   return mainData
  }
 

 
  const handleGroupByChange = (e) => {
    if(e.target.value!==groupByFilter) {
      // reset expandable rule items
      setCubeChildExpendableRuleItems([]);

      // Change Filters According to groupTypes
      if(e.target.value?.includes("Broad")) {
        setIsLoaderVisible(true)
        setCubeQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.broadCategory",
        ])
        setCubeChildRowsQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.broadCategory",
          "VariantAnalysisTablePPMPlaceholder.category"
        ])
        // const prevDataParentFilters = [...cubeQueryFilters]
        // const parentQueryFilters = filterOutFilters(groupByFilter,prevDataParentFilters);
        // const childQueryFilters = filterOutFilters(groupByFilter,cubeChildRowsQueryFilters);
        // setCubeChildRowsQueryFilters(childQueryFilters)
        // setCubeQueryFilters(parentQueryFilters)
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "broadCategory";
          prev.revenue = "sales14d"
          return prev
        })
      } else if(e.target.value?.includes("Detailed")) {
        setIsLoaderVisible(true)
        // const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
        // setCubeQueryFilters(filteredFilters)
        setCubeQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.category",
        ])
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "category";
          prev.revenue = "sales14d"
          return prev
        })

      } else {
       setViewType("Child Asin")
      //  const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
       // Take out normal and range Filters and send it cubeQueryFilters
      //  const rangeFilters = filteredFilters?.filter((data) => data?.operator?.includes("gte") || data?.operator?.includes("lte"))
      //  const normalFilters = filteredFilters?.filter((data) => !data?.operator?.includes("gte") && !data?.operator?.includes("lte"))
      //  setCubeQueryFilters([{and:normalFilters},{and:rangeFilters}])
      //  setCubeChildRowsQueryFilters([{and:normalFilters},{and:rangeFilters}])
       setCubeQueryDimensions(["VariantAnalysisTablePPMPlaceholder.asin",
       "VariantAnalysisTablePPMPlaceholder.productNameWithImage"])
       setCubeChildRowsQueryDimensions(["VariantAnalysisTablePPMPlaceholder.asin",
       "VariantAnalysisTablePPMPlaceholder.productNameWithImage"])
       setGroupByFilter(e.target.value)
       setChartFilters((prev) => {
        prev.spend = "cost";
        prev.dimension = "asin";
        prev.revenue = "sales14d";
        return prev
      })

      }
    }
  }

  function removeQueryParamAndSetUrl(key) {
    // Get the current URL
    const urlObj = new URL(window.location.href);

    // Delete the specific query parameter
    urlObj.searchParams.delete(key);
    // Update the browser's URL without reloading the page
    window.history.replaceState({}, document.title, urlObj.toString());
  }


  const handleQueryChange = (urlQuery) => {
    const urlQueryFilter= JSON.parse(decodeURIComponent(urlQuery ? urlQuery : "{}"));
    let filterCustomFormat = reverseMergeAPIFiltersToFilterModuleFormat(urlQueryFilter);
    if(filterCustomFormat?.metrics?.custom_output || filterCustomFormat?.dimension?.custom_output || filterCustomFormat?.campaignAmazon?.custom_output) {
      setIsFilterApplied(true);
    }
    // handleDataFetch(filterCustomFormat);
    setFilterModuleFilters((prev)=>{
      return JSON.parse(JSON.stringify(filterCustomFormat));
    })
    removeQueryParamAndSetUrl("urlQuery");
     return filterCustomFormat;
  }

  function handleDynamicPageSaveFunction () {
    const function_mapper = {
      'stoploss':handleSaveStoplossRuleFunction,
      'productset':handleSaveProductSet
    }
    const functionToRun = function_mapper[pageType];
    functionToRun();
  }

  // useEffect(() => {
  //   const urlQueryFilter= JSON.parse(decodeURIComponent(urlQuery ? urlQuery : "{}"));
  //   let filterCustomFormat = reverseMergeAPIFiltersToFilterModuleFormat(urlQueryFilter);
  //   if(filterCustomFormat?.metrics?.custom_output || filterCustomFormat?.dimension?.custom_output || filterCustomFormat?.campaignAmazon?.custom_output) {
  //     setIsFilterApplied(true);
  //   }
  //   // handleDataFetch(filterCustomFormat);
  //   setFilterModuleFilters((prev)=>{
  //     return JSON.parse(JSON.stringify(filterCustomFormat));
  //   })
  // }, [urlQuery]);

  const pageDisplayType = (viewType==="Child Asin" ? "product-child" : viewType === "Parent Asin" ? "product-parent" : groupByFilter?.includes("Broad") ? "expendable" : "category");
  const pageDisplayFormat = (viewType==="Child Asin" ? "campaign-format_child" : viewType === "Parent Asin" ? "campaign_format" : groupByFilter?.includes("Broad") ? "campaign_format" : "");
  const childrenOneHeader = viewType!==null ? "Product Name" : "Category"
  const reportHeading = groupByFilter?.includes("Broad") ? "Broad Category Report" : groupByFilter?.includes("Detailed") ? "Detailed Category Report":"Product Report"

  const generateStopLossHeading = (pslRuleId) => {
    return pslRuleId ? "Update" : "Create"
  }

  const handleFilterNavigate = (pageName) => {
    if(pageName === "PRODUCT_SET") {
      navigate('/product-analytics?reportType=Product&viewtype=Performance+View&page=productset');
    }else if(pageName === "STOP_LOSS") {
      navigate('/product-analytics?reportType=Product&viewtype=Performance+View&page=stoploss');
    }
  }

  useEffect(() => {
    async function fetchStopLossData() {
     await axios.get(`${process.env.REACT_APP_ADS_API}/client/${clientId}/automation-rule-set/${pslRuleId}?source=amazon`).then((data) =>{
      let stopLossData = data.data;
      let apiFilter = {};
      if(getCubeFiltersByActionType(stopLossData?.rule_tasks, "PAUSE_PRODUCTS")){
        let parsedQuery = JSON.parse(getCubeFiltersByActionType(stopLossData?.rule_tasks, "PAUSE_PRODUCTS") ?? "{}");
        setCustomDate(parsedQuery?.query?.date_preset);
         let filterCustomFormatExclusion = reverseMergeAPIFiltersToFilterModuleFormat(parsedQuery?.query?.filters);
         let updatedDimension = removeRuleItemByMember(filterCustomFormatExclusion?.dimension,"VariantAnalysisTablePPMPlaceholder.profileId");
         let updatedAmazonCampaign = removeRuleItemByMember(filterCustomFormatExclusion?.campaignAmazon,"VariantAnalysisTablePPMPlaceholder.campaignFormat");
         filterCustomFormatExclusion.dimension = updatedDimension;
        filterCustomFormatExclusion.campaignAmazon = updatedAmazonCampaign;
        apiFilter = filterCustomFormatExclusion;
         setFilterModuleFilters(filterCustomFormatExclusion);
      }
      handleDataFetch(apiFilter);

      if(getCubeFiltersByActionType(stopLossData?.rule_tasks, "ENABLE_PRODUCTS")){
        let parsedQuery = JSON.parse(getCubeFiltersByActionType(stopLossData?.rule_tasks, "ENABLE_PRODUCTS") ?? "{}");
        setInclusionCustomDate(parsedQuery?.query?.date_preset);
         let filterCustomFormatInclusion = reverseMergeAPIFiltersToFilterModuleFormat(parsedQuery?.query?.filters);
         let updatedDimension = removeRuleItemByMember(filterCustomFormatInclusion?.dimension,"VariantAnalysisTablePPMPlaceholder.profileId");
          let updatedAmazonCampaign = removeRuleItemByMember(filterCustomFormatInclusion?.campaignAmazon,"VariantAnalysisTablePPMPlaceholder.campaignFormat");
          filterCustomFormatInclusion.dimension = updatedDimension;
          filterCustomFormatInclusion.campaignAmazon = updatedAmazonCampaign;
         setInclusionFilterModuleFilters(filterCustomFormatInclusion);
      }
        
      setStoplossRuleDetail((prev) => {
        let data = { ...prev }
        data['name'] = stopLossData?.name;
        data['frequency'] = stopLossData?.schedule_config?.day_frequency;
        data['recommend_only'] = !stopLossData?.apply_decision;
        data['stoploss_running_days'] = stopLossData?.schedule_config?.frequency_values;
        data['max_percent_exclude'] = stopLossData?.rule_tasks?.[0]?.rule_action_config?.max_percent_exclude;
        
        return data;
    });
     }).catch((error) => {
       console.log(error);
       toast.error("Error while fetching Stoploss Rule Data. Try Again!")
     });
    }
    
    if(pslRuleId){
     fetchStopLossData();
     setIsPSLRuleFilterLoading(false);
    }
    setInitialLoad(false);
  }, [pslRuleId]);

  useEffect(() => {
    const init = async () => {
      const precessedDimensionData = await processFields(
        FiltersDimensionDataQuadrant.fields,
        clientId,
        []
      );
      FiltersDimensionDataQuadrant.fields = precessedDimensionData;
      const precessedMeasuresData = await processFields(
        FiltersMeasuresDataQuadrant.fields,
        clientId,
        []
      );
      FiltersMeasuresDataQuadrant.fields = precessedMeasuresData;
      const precessedAmazonData = await processFields(
        FiltersAmazonDataQuadrant.fields,
        clientId,
        []
      );
      FiltersAmazonDataQuadrant.fields = precessedAmazonData;
    };
    init();
  }, [clientId]); 

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <ReactTooltip 
            id="create-product-set-tooltip"
            style={{ zIndex: "999999", fontSize: "14px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
          <ReactTooltip 
            id="stoploss-tooltip"
            style={{ zIndex: "999999", fontSize: "14px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
      <Loader visible={initialLoad}></Loader>

      <div
        style={{
          marginLeft: "auto",
        }}
      >
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent
          setShowCustomDateBox={setShowCustomDateBox}
        ></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>

      {!isPageUnderDynamicSection && <Breadcrumb />}
      {!isPageUnderDynamicSection &&
        <SectionTitle sectionTitleHeading={reportHeading}></SectionTitle>
      }
     {isPageUnderDynamicSection &&  <div style={{display:"flex",justifyContent:"space-between"}}>
      <div className="d-flex align-items-center gap-2">
        <img height={16} style={{cursor:"pointer"}} src={leftArrow} onClick={()=>{
       return isStopLossPage ? navigate("/stop-loss-list") : isProductSetPage ? navigate('/product-set-list')  : null
          }} alt="leftArrow"/>
        <SectionTitle sectionTitleHeading={generateStopLossHeading(pslRuleId ?? productsetId) + pageTypeConstantHeading}></SectionTitle></div>
      <div className="d-flex gap-3">
    {isProductSetPage ?  <button className="primary-inactive-btn" onClick={() => {
      navigate('/product-set-list')
    }}>
        Cancel
      </button> : null}
      <button className="primary-active-btn" onClick={handleDynamicPageSaveFunction}>
        {isStoplossSaving ? "Saving..." : generateStopLossHeading(pslRuleId ?? productsetId) + (isStopLossPage ? ' Rule' : '')}
      </button>
      </div>
      </div>}
      {isPageUnderDynamicSection && <p className="info-text">Analytics is displayed as per the selected day range in the filter.</p>}
     {!isPageUnderDynamicSection && <div
        className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative"
        id="downloadChart"
      >
        <div
          onClick={handleDownload}
          className="downloadChartWrapper pie"
          id="downloadChartWrapper"
        >
          <img
            src={downloadIcon}
            className="cursor-pointer-global DownloadIcon"
            alt="overview-settings-tile"
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>}
      {
       isProductSetPage ? <>
         {/* <p className="pageTitleText mb-3">Create Smart Product Set For</p> */}
         <div className="card-style-new mt-2">
           <div className="row">
           <div className="col-lg-6">
           <p className="dropdown-text">Select Campaigns</p>
   
           <MultiSelect
                     defaultSelected={selectedCampaigns}
                     options={allCampaignsOptions ?? []}
                     renderItem={renderCampaignLevelItem}
                     showLimitedOptions={true}
                     limitedOptionsLimit={100}
                     onApply={(e) => {
                       setSelectedCampaigns(e)
                       setSelectedAdGroups([]);
                     }}
                     isSearch={true}
                     placeHolder={"Search For Campaigns"}
                     classes={"mst-negative-modal-drop-down-height"}
                   />
             </div>
             <div className="col-lg-6">
             <p className="dropdown-text">Select Adgroup</p>
   
             <MultiSelect
                     defaultSelected={selectedAdGroups}
                     options={mergedDataOptionsForDropDown ?? []}
                     showLimitedOptions={true}
                     limitedOptionsLimit={100}
                     onApply={(e) => {
                       setSelectedAdGroups(e);
                     }}
                     isSearch={true}
                     placeHolder={"Search For Campaigns"}
                     classes={"mst-negative-modal-drop-down-height"}
                   />
             </div>
           </div>
         </div>
         </> : null
      }

      {!isPageUnderDynamicSection && <div className="d-flex w-100 align-items-end justify-items-between pt-3 productradiobutton">
      <CustomDropDown data={groupByOptions} classes={'product-report-group-by-filter'} filterCampaigns={handleGroupByChange} targettingValue={groupByFilter} src={dropdownIcon} title={"Group Data By"}/>
        <div className="w-100 d-flex flex-column gap-2">
         
   {    groupByFilter === "Product" ?   <RadioInputGroup
            state={viewType}
            setState={setViewType}
            name="viewType"
            radios={["Parent Asin", "Child Asin"]}
            required
          ></RadioInputGroup> : null}
           <small className="paginationtext">*SB Data Not Present</small>
        </div>
      </div>}
      {isStopLossPage ? <StopLossFilters
                filterModuleFilters={filterModuleFilters}
                inclusionFilterModuleFilters={inclusionFilterModuleFilters}
                handleFilterOutput={handleFilterOutput}
                handleInclusionFilterOutput={handleInclusionFilterOutput}
                // fetchingData={fetchingData}
                handleFilterApplied={()=>{}}
                handleCustomDate={handleCustomDate}
                customDate={customDate}
                inclusionCustomDate={inclusionCustomDate}
                handleInclusionCustomDate={handleInclusionCustomDate}
                stoplossRuleDetail={stoplossRuleDetail}
                setStoplossRuleDetail={setStoplossRuleDetail}
                handleStoplossRuleDetail={handleStoplossRuleDetail}
                errors={errors}
                pslRuleId={pslRuleId}
                // dateOffset={dateOffset}
            /> : null}
      {
        isProductSetPage ? <ProductSetFilters filterModuleFilters={filterModuleFilters} handleFilterOutput={handleFilterOutput} productSetDetail={productSetDetail}
        // setStoplossRuleDetail={setStoplossRuleDetail}
        handleCustomDate={handleCustomDate}
        customDate={customDate}
        handleProductSetDetail={handleProductSetDetail} 
        errors={errors}/> : null
      }
      <div>
        {         
          <Chart
            key="table_render"
            type={tableView}
            resultSet={resultSet}
            classes={`noFilters sortByApi ${viewType!==null ? "wraptext" : ""} marketing-reports`}
            display={pageDisplayFormat}
            showCustomFilterBtn={!isPageUnderDynamicSection}
            isFilterApplied={isFilterApplied}
            handleCustomBtnClick={() => setShowFilterModal(true)}
            handleChange={expendChange}
            childrenOneData={childrenOne}
            shimmerEffect={isLoaderVisible}
            childrenOneHeader={childrenOneHeader}
            FunnelViews={[
              "Performance View",
              "Funnel View",
              "Comparison View",
              "Custom View",
            ]}
            handleComparisonView={handleComparisonView}
            handleFilterNavigate={handleFilterNavigate}
            CurrentViewType={currentViewType}
            page={pageDisplayType}
            dataFilter={()=>{}}
            sortData={sortData}
            drrView={handleDrrView}
            drrViewType={drrView}
            handleSearch={()=>{}}
            showFilter={false}
            groupByView={groupByFilter}
          />
      //    : viewType === "Child Asin" ? (
      //     <Chart
      //       type={tableView}
      //       resultSet={resultSet}
      //       shimmerEffect={isLoaderVisible}
      //       classes={"wraptext marketing-reports"}
      //       display={"campaign_format_child"}
      //       handleChange={expendChange}
      //       childrenOneHeader={"Product Name"}
      //       FunnelViews={[
      //         "Performance View",
      //         "Funnel View",
      //         "Comparison View",
      //         "Custom View",
      //       ]}
      //       handleComparisonView={handleComparisonView}
      //       CurrentViewType={currentViewType}
      //       page="product-child"
      //       dataFilter={dataFilter}
      //       drrView={handleDrrView}
      //       drrViewType={drrView}
      //       handleSearch={handleSearch}
      //       showFilter={showFilters}
      //       key={currentViewType}            
      //     />
      //   ):<Chart
      //   type={tableView}
      //   resultSet={resultSet}
      //   classes={"marketing-reports"}
      //   display={groupByFilter?.includes('Broad') ? 'campaign_format' : undefined}
      //   page={groupByFilter?.includes('Broad') ? 'expendable':'category'}
      //   handleChange={expendChange}
      //   childrenOneData={childrenOne}
      //   shimmerEffect={isLoaderVisible}
      //   childrenOneHeader={'Category'}
      //   FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} 
      //   handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} 
      //   dataFilter={dataFilter}
      //   drrView={handleDrrView} drrViewType={drrView}
      //   handleSearch={handleSearch}
      //   showFilter={showFilters}
      //   key={groupByFilter}
      // />
      }
      <div className="d-flex align-items-end gap-1 py-2 justify-content-end">
                 <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    lazyLoading={isLoaderVisible}
                   />
      </div>
      </div>
       {/*filter start==================================== */}
       <FilterWidget
        key="product_analytics_page"
        config={{
          campaignAmazon: FiltersAmazonDataQuadrant,
          measure: FiltersMeasuresDataQuadrant,
          dimension: FiltersDimensionDataQuadrant,
        }}
        campaignFilter={true}
        filters={filterModuleFilters}
        showPopupModal={showFilterModal}
        setShowPopupModal={setShowFilterModal}
        output={handleFilterOutput}
        prefixMember={""}
        type="popup"
        sidebar={true}
        default={"metrics"}
        page={"overlay"}
        isApplied={() => {}}
        summary={"always"}
        narrowFilters={false}
        summaryOutput={(e) => {}}
        calloutTitle="Select your filters"
        calloutContent="+ Add Filter"
        // calloutDescription="Add new attributes at campaign level to determine campaign funnel"
      />

      {/*filter end======================================= */}
    </div>
  );
};

export default ProductReport;
