import React from "react";
import { Table } from "react-bootstrap";
import Spinner from "../spinners/spinner";
import LazyLoadSpinner from "../spinners/LazyLoadSpinner";
import "./tableRb.css";
import { humanize } from "../Reports/Components/utils";
import DataNotFound from "../DataNotFound/DataNotFound";
//use props.tableHeadRow to define table head
//use props.TableData in array of array format to fill table data
const TableRb = (props) => {
  // let dataa = props.TableData ? props.TableData.map((DataArray) => {
  //     console.log(DataArray);
  //     return <tr>
  //         {DataArray.map((data) => {
  //             console.log(data);
  //             return <td>{data}</td>
  //         })}
  //     </tr>

  // }) : "No Table Data Found";


  const dummyDataForSkeleton = Array.from({ length: 50 }, (_, index) => `row_${index + 1}`);

  return (
    <>
    <div
      className={
        props?.className
          ? "main-tableRb-body position-relative d-flex flex-column" + props?.className
          : "main-tableRb-body position-relative d-flex flex-column"
      }
    >
      {/* <div className='tableRb-data-container'> */}
      {/* <div>{props.title ? props.title : null}</div> */}
      <div
        className={
          "tableRb text-center" +
          (props?.tableclassName ? props?.tableclassName : "")
        }
      >
        {/* {props.pageVariant === "first"?<> <div className="small-container">
                    </div></>:props.table} */}
        {props.isLoading && (
          <div className="good position-absolute top-50 start-50">
            <Spinner size={"small"} shape={"border"} />
          </div>
        )}

        {props.shimmer ? (
          <>
            <div className="">
              <table className="campaign-list-table campaign-list-center">
                <thead className="fixed-table">
                  <tr className="main-table-row-fixed">
                    {props.tableHeads?.map((th, mainIndex) => {
                      return <th key={th?.id} className={props?.hiddenKeys?.includes(mainIndex) ? "d-none" : "targeting-table-heads"}> {th.fieldName ?? humanize(th.shortTitle)} </th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dummyDataForSkeleton?.map((row, index) => {
                    return (
                      <tr key={row}>
                        {props.tableHeads?.map((data, mainIndex) => {
                          return (
                            <td key={data?.id} style={{border:"1px solid #ccc"}} className={props?.hiddenKeys?.includes(mainIndex) ? "d-none" : ""}>
                              <div className="td-with-loader">
                                <Spinner shimmer={true} />
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : props?.noDataFound ? (
          <>
            <DataNotFound description={props?.noDataFoundDescription}/>
          </>
        ) : (
          props.table
        )}

        {/* {
          props?.noDataFound && (
            <>
                <DataNotFound description={"Data Not Found"}/>
            </>
          )
        } */}

        {/* <thead>
                <tr >{props.tableHeadRow ? props.tableHeadRow.map((trTitle) => <th  >{trTitle}</th>) : ""} </tr>
            </thead> */}
        {/* <tbody>{dataa}</tbody> */}
        {/* <div className="lazy-loading-container">
         <LazyLoadSpinner text={"Loading 50 More Targetings..."} />
      </div> */}
      </div>
      {/* </div> */}
     
    </div>
  
    </>
  );
};

export default TableRb;

// DataArray.map((data, i, array) => {
//     return (
//       <td className="tabledata-td">
//         {i === 2 || i === 3 ? (
//           <div className="d-flex justify-content-evenly align-items-center budget-td-container">
//             <span className="budget-no w-100">{data}</span>
//             {/* <span className="edit-budget-icon-container"><img src={editIcon}></img></span> */}
//           </div>
//         ) : (
//           <div className="d-flex justify-content-evenly align-items-center">
//             {data}
//           </div>
//         )}
//       </td>
//     );
//   })
