import React from "react";
import AuthNav from "./AuthNav";
import customizable from "./Images/customizable.svg";
import icon2 from "./Images/report.svg";
import icon3 from "./Images/optimization.svg";
import icon4 from "./Images/campaign.svg";
import visibility_off from "./Images/visibility_off.png";
import visibility from "./Images/visibility.png";
import "./LogIn.css";
import getClientData from "../../helper/clientData/getClientData";
import { useGlobalContext } from "../../context/global";
import { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Adyogi_logoz.svg";
import ForgetPasswordModal from "../../components/modal/ForgetPassword/ForgetPasswordModal";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import Spinner from "../../components/spinners/spinner";
import axios from "axios";
import { toast } from "react-toastify";
import { generateIFrameReportUrl, getClientAccountDetails, getPlatformStatus } from "../../helper/clientData/utils";

const LogIn = () => {
  const [showpass, setshowpass] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const {
    setClientId,
    setClientAccountId,
    setClientData,
    campaignData,
    setCampaignData,
    adGroupData,
    setAdGroupData,
    adSetData,
    setAdSetData,
    setReportData,
    showAlert,
    buttonStatus,
    setButtonStatus,
    setNoMultiuserClients,
    config,
    setShowCMSLink,
    setSelectedPlatform,
    updatePlatform
  } = useGlobalContext();
  const navigate = useNavigate();
  const SignIn = useSignIn();

  function setCurrentClientData(mainData) {
    const { reportData, clientData } = mainData;
    const { client_id, account_id, default_bid } = clientData;
    setClientData(clientData);
    const {myntraStatus,flipkartStatus,amazonStatus} = getPlatformStatus(clientData)
    const platform = amazonStatus ? "amazon" : flipkartStatus ? "flipkart" : myntraStatus ? "myntra" : "amazon"
    updatePlatform(platform)
   
    localStorage.setItem("reference",JSON.stringify(clientData))
    setClientId(client_id);
    setAdGroupData({
      ...adGroupData,
      account_id: account_id,
      amz_default_bid: default_bid,
    });
    setCampaignData({
      ...campaignData,
      tenant_id: client_id,
      account_id: account_id,
    });
    setAdSetData({ ...adSetData, tenant_id: client_id });
    setClientAccountId(account_id);
    setReportData(reportData);
    if(platform==="flipkart") {
      const url = generateIFrameReportUrl("flipkart","Day",client_id)
      navigate(`/reports?report=${url}`)
    } else if (platform==="myntra") { 
      const url = generateIFrameReportUrl("myntra","Day",client_id)
      navigate(`/reports?report=${url}`)
    } else if(platform==="amazon") {
      navigate("/")
    }
  }

  async function forgetPasswordValidator() {
    if (email.trim() === "") {
      setError(true);
      return false;
    } else {
      try {
        const url = `https://app1.adyogi.com/api/login/forgetPassword`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        };
        const jsonResponse = await fetch(url, options);
        const response = await jsonResponse.json();
        if (response.success) {
          setModalShow(true);
        } else if (response.error) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: response.error,
          // });
          toast.error(`${response.error}`)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getClientsFromMyntraAndFlipkart = async () => { 
    try {
      const url = `${process.env.REACT_APP_LOGIN_API}/api/B4APIs/getAllClients`
      const config = {
        method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${process.env.REACT_APP_AUTHORIZATION_FOR_NORMAL_USER}`
            }
      }
      const data = await axios.get(url,config);
      if(data?.status === 200) {
        return data?.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Fetching Methods

  const convertFlipkartAndMyntraData = (clientsData) => {
    return clientsData?.reduce((acc,curr) => {
      const setupKey = (curr?.flipkart_setup && curr?.flipkart_setup?.profile_id) ? 'flipkart_setup' : 'myntra_setup'
    if(curr[setupKey] && curr[setupKey]?.profile_id) {
     const {accountName,accountType,profileId} = getClientAccountDetails(curr)
      acc.push({
        ...curr,
        name: accountName,
        account_id: profileId,
        client_id: curr?.objectId,
        account_type: accountType,
        isMyntraOrFlipkart:true,
        platform: setupKey === 'flipkart_setup' ? 'flipkart' : 'myntra'
      })
    }

    return acc
    },[])
  }

  const mergeAmazonDataWithFlipkartAndMyntra = (amazonData,flipkartAndMyntraData) => {
    return amazonData?.map((ele) => {
      let flipkartAndMyntraAccountDetails = flipkartAndMyntraData?.find((item) => item?.client_id === ele?.parse_client_id)
      return {
        ...ele,
        ...flipkartAndMyntraAccountDetails
      }
    })
  };



  const getAllClientsWithMyntraAndFlipkart = async (amazonResponse) => {
      try {
        const flipkartAndMyntraResponse = await getClientsFromMyntraAndFlipkart();
        const modifiedFlipkartData = convertFlipkartAndMyntraData(flipkartAndMyntraResponse)
        const overallData = mergeAmazonDataWithFlipkartAndMyntra(amazonResponse,modifiedFlipkartData);
        return overallData;
      } catch (error) {
        console.log(error)
      }
    }

  async function postdata() {
    try {
      setButtonStatus({ status: true, text: "Signing In..." });
      const response = await fetch(
        "https://app1.adyogi.com/api/login/generateToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "AMZN-D1457662DFDC43CD8CB7D856C341D",
          },
          body: JSON.stringify({
            // your expected POST request payload goes here
            email: email,
            password: password,
          }),
        }
      );
      const data = await response.json();
      // enter you logic when the fetch is successful

      if (data.success === true) {
        if (
          SignIn({
            token: data.token,
            expiresIn: 4800,
            tokenType: "Bearer",
            authState: data,
          })
        ) {
          if (data?.user?.multiUser === "no") {
            // Save Client Id in localStoragge
            localStorage.setItem(
              "clientId",
              JSON.stringify(data?.user.clientId)
            );
            localStorage.setItem("email", data?.user.email);
            // Get All Client Data
            const mainData = await getClientData(
              data?.user.clientId,
              true,
              data?.user?.email
            );
            await fetchData(data?.user?.clientId)
            // Get the clients which are mapped on User Account
            const userAccounts = await fetch(
              `https://app1.adyogi.com/api/amzn/getUserMappingDetails?email=${data?.user?.email}`,
              {
                headers: {
                  Authorization: process.env.REACT_APP_AUTHORIZATION_FOR_NORMAL_USER,
                },
              }
            );
            const userAccountsJson = await userAccounts.json();
           let overAllData =await getAllClientsWithMyntraAndFlipkart(userAccountsJson)
            // If there are Account Sync with Localstorage and Context
            if (userAccounts.status === 200) {
              localStorage.setItem(
                "userAccounts",
                JSON.stringify(overAllData)
              );
              setNoMultiuserClients(overAllData);  
            }
            setCurrentClientData(mainData);
            setButtonStatus({ status: false, text: "" });
          } else {
            localStorage.setItem(
              "clientId",
              JSON.stringify(data?.user.clientId)
            );
            localStorage.setItem("email", data?.user.email);
            const mainData = await getClientData(
              data?.user.clientId,
              true,
              data?.user?.email
            );
            await fetchData(data?.user?.clientId)
            setCurrentClientData(mainData);
            setButtonStatus({ status: false, text: "" });
            // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
            //   backgroundColor: TOAST_SUCCESS,
            //   title: "Success",
            //   description: "Please Choose Client",
            // });
          }
        }
      } else {
        setButtonStatus({ status: false, text: "" });
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Invalid Credentials, Please Check!",
        // });
        toast.error('Invalid Credentials, Please Check!')
      }
    } catch (error) {
      // enter your logic for when there is an error (ex. error toast)
      setButtonStatus({ status: false, text: "" });
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: "Unknown Error Occured!Please Contact Admin!",
      // });
      toast.error('Unknown Error Occured!Please Contact Admin!')
      console.log(error);
    }
  }

  const togglepass = () => {
    setshowpass((prevState) => !prevState);
  };

  const fetchData = async (clientId) => {
    try {
      const response = await axios.get('https://pamphlet.back4app.io/classes/Client?where={ "objectId":"' + clientId + '" }', {
        headers: {
          'X-Parse-Application-Id': '0f5uiNLZwPItPmo2g10gQlaCpglrbzkpuRnXUSqp',
          'X-Parse-REST-API-Key': 'lNPIUzmgFLoLbX4F1YqJ9RhRFRd7gfOwOM3OugrG',
          'X-Parse-Session-Token': 'r:97f814adf62536301f368c2156b86c41'
        }
      });
      const productType = response?.data?.results[0]?.amazon_setup?.product_type
      localStorage.setItem("product_type",JSON.stringify(productType ?? "internal"))
      if( productType==="Self serve") {
        setShowCMSLink(false)
      } else {
        setShowCMSLink(true)
      }
      
    } catch (error) {
      toast.error('Unknown Error Occurred while fetching Product Type')
      throw error;
    }
  }


  return (
    <>
      {/* <AuthNav /> */}

      <ForgetPasswordModal
        show={modalShow}
        email={email}
        onHide={() => setModalShow(false)}
      />

      <div className="LogIn-Main-container">
        {/* login left box.............................................................. */}
     
        <div className="login-left-box">
          <div className="login-logo">
          {config ? <img className="logo" src={"/logo/"+config.logo_white} alt="logo"/> : <img className="logo" src={Logo} alt="logo"/>}
          </div>
          <div className="login-heading">
            <span>Advanced automated Advertising</span>
          </div>

          <div className="Signin-icon-main-box">
            <div className="Signin-icon-flex-box">
              <div className="login-icon-flex-box-image">
                <img src={icon2} alt="" className="dashboardIconClass" />
              </div>

              <div className="Signin-icon-flex-box-content">
                <span className="login-icon-flex-box-content-span-1">
                  Advanced Reporting and Analysis
                </span>
                <span className="login-icon-flex-box-content-span-2">
                  Compare historical data with current performance to derive
                  valuable insights.
                </span>
              </div>
            </div>

            <div className="Signin-icon-flex-box">
              <div className="login-icon-flex-box-image">
                <img src={icon3} alt="" className="dashboardIconClass" />
              </div>

              <div className="Signin-icon-flex-box-content">
                <span className="login-icon-flex-box-content-span-1">
                  Performance Optimization
                </span>
                <span className="login-icon-flex-box-content-span-2">
                  Know where your ASINs and keywords are running within minutes
                  and optimize effectively.
                </span>
              </div>
            </div>

            <div className="Signin-icon-flex-box">
              <div className="login-icon-flex-box-image">
                <img src={customizable} alt="" className="dashboardIconClass" />
              </div>

              <div className="Signin-icon-flex-box-content">
                <span className="login-icon-flex-box-content-span-1">
                  Customizable Dashboard
                </span>
                <span className="login-icon-flex-box-content-span-2">
                  Customize your data as per your brand’s KPIs.
                </span>
              </div>
            </div>

            <div className="Signin-icon-flex-box">
              <div className="login-icon-flex-box-image">
                <img src={icon4} alt="" className="dashboardIconClass" />
              </div>

              <div className="Signin-icon-flex-box-content">
                <span className="login-icon-flex-box-content-span-1">
                  Campaign and Ads Creation
                </span>
                <span className="login-icon-flex-box-content-span-2">
                  Set up, manage, and optimize your campaigns all from a single
                  dashboard. Scale your ROAS using AdYogi’s catalog-linked Smart
                  Ads.
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* login right box................................................................ */}
        <div className="LogIn-Right-box">
          <div className="LogIn-Right-box-heading">
            <span>Sign In</span>
          </div>

          <div className="LogIn-Right-box-content">
            <div className="LogIn-Right-box-content-input-1">
              <label className={error ? "Login-Error-1" : "LogIn-label-1"}>
                Your Email
              </label>
              <input
                type="email"
                className={error ? "LogIn-input-1-error" : "LogIn-input-1"}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <span className={error ? "Login-Error-1" : "d-none"}>
                Kindly check your mail Id
              </span>
            </div>

            <div className="LogIn-Right-box-content-input-1">
              <label className="LogIn-label-1">Your Password</label>
              <div className="login-eyecheck">
                <input
                  type={showpass ? "text" : "password"}
                  className={showpass ? "LogIn-input-1" : "LogIn-input-1-1"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
                {showpass ? (
                  <span className="logIn-hide1-log">
                    <img src={visibility_off} alt="" onClick={togglepass} />
                  </span>
                ) : (
                  <span className="logIn-show1-log">
                    <img src={visibility} alt="" onClick={togglepass} />
                  </span>
                )}
              </div>
            </div>

            <div className="LogIn-Forget-password">
              <div className="LogIn-remember-me">
                <input type="checkbox" name="" id="" />

                <span>Remember me</span>
              </div>

              <div className="forget" onClick={() => forgetPasswordValidator()}>
                Forgot password?
              </div>
            </div>

            <div className="LogIn-SignIn-btn">
              <Button
                disabled={buttonStatus.status}
                onClick={() => postdata(email, password)}
              >
                {buttonStatus.status ? (
                  <>
                    <Spinner size={"sm"} shape={"border"} /> Signing In...
                  </>
                ) : (
                  "Sign In"
                )}
              </Button>
              <div className="LogIn-tc">
                <span>
                  Clicking on Sign In you will agree to Terms & Conditions
                </span>
              </div>
              {/* <div className="LogIn-no-account d-flex">
                <span>
                 Don't have an account?
                </span>
                <div className="signup-option">
                  Sign up
                </div>
              </div> */}
            </div>

            <div className="LogIn-account d-none">
              <span>Don't have an account?</span>
              <span id="LogIn-account-link-span">Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
