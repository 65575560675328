import React from "react";
import { Form } from "react-bootstrap";

const CustomTextField = ({ placeholder, value, error, onChange,...props }) => (
  <Form.Control
    type="text"
    size="lg"
    placeholder={placeholder}
    value={value}
    isInvalid={error}
    // styles
    
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

export default CustomTextField;
