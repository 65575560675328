import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context/global";
import { useRef } from "react";
import { Stack } from "react-bootstrap";
import axios from "axios";
// for calender
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken  from "../../helper/Reports/useToken";
import Loader from "../../components/loader";
import { toast } from "react-toastify";

const MarketingStream = (props) => {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const { calendarDate, applyCalendar } = useGlobalContext();
  const { clientId } = useGlobalContext();
  const [ entityID, setEntityID ] = useState(false);
  const [resultSetComparison, setResultSetComparison] = useState({})

  const fetchData = async () => {
    const response = await axios.get('https://pamphlet.back4app.io/classes/Client?where={ "objectId":"'+clientId+'" }', {
    headers: {
      'X-Parse-Application-Id': '0f5uiNLZwPItPmo2g10gQlaCpglrbzkpuRnXUSqp',
      'X-Parse-REST-API-Key': 'lNPIUzmgFLoLbX4F1YqJ9RhRFRd7gfOwOM3OugrG',
      'X-Parse-Session-Token': 'r:97f814adf62536301f368c2156b86c41'
    }
  });
    setEntityID(response.data.results[0].amazon_setup.profile_info.accountInfo.id);
  }

  fetchData();
  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_MARKETING_CUBE_JS_URL,
  });

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [resultSet, setResultSet] = useState({});
  var cpcResultSet = formatPieChartData(resultSet,'cpc',1);
  var conversionRateResultSet = formatPieChartData(resultSet,'conversion_rate',2);
  var costResultSet = formatPieChartData(resultSet,'cost',3);
  var acosResultSet = formatPieChartData(resultSet,'acos',4);
  var impressionsResultSet = formatPieChartData(resultSet,'impressions',5);
  var clicksResultSet = formatPieChartData(resultSet,'clicks',6);
  const { dateDifference } = useGlobalContext();

  const calenderIconRefForCampaignlist = useRef(null);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };
  useEffect(() => {
    setIsLoaderVisible(true)
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ];
      return data;
    });
  }, [applyCalendar]);

  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "ams_sp_traffic.cost",
    "ams_sp_traffic.sales14d",
    "ams_sp_traffic.roas",
    "ams_sp_traffic.acos",
    "ams_sp_traffic.impressions",
    "ams_sp_traffic.clicks",
    "ams_sp_traffic.conversions14d",
    "ams_sp_traffic.TotalOrderItemCount",
    "ams_sp_traffic.average_order_value",
    "ams_sp_traffic.cpc",
    "ams_sp_traffic.cpm",
    "ams_sp_traffic.ctr",
    "ams_sp_traffic.conversion_rate",
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState({
    "ams_sp_traffic.hour":"asc"
  });

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "ams_sp_traffic.hour"
  ]);
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "ams_sp_traffic.time_window_start",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([]);

  useEffect(() => {
    setCubeQueryFilters([
      {
        member: "ams_sp_traffic.advertiser_id",
        operator: "equals",
        values: [entityID],
      },
    ])
  },[entityID])
  useEffect(() => {
    //table data
    if(cubeToken && entityID) {
      cubejsApi
      .load({
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        dimensions: cubeQueryDimensions,
        filters: cubeQueryFilters,
        timeDimensions: [
          {
            "dimension": "ams_sp_traffic.time_window_start",
            "dateRange": [
              formatDate(new Date()),
              formatDate(new Date()),
            ]
          }
        ],
        timezone:'Asia/Kolkata',
        limit: 500,
      },{
        headers: {
          'Access-Control-Allow-Origin': true
        }
      })
      .then((results) => {
        setResultSetComparison(results);
      }).catch((error) => {
        toast.error(error.response?.error)
        if(error.message === 'Invalid token') {
            setCubeToken(false)
            localStorage.setItem("cubeToken", null);
            refresh()
        }
      });

    cubejsApi
      .load({
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        dimensions: cubeQueryDimensions,
        filters: cubeQueryFilters,
        timeDimensions: cubeQueryTimeDimensions,
        timezone:'Asia/Kolkata',
        limit: 500,
      },{
        headers: {
          'Access-Control-Allow-Origin': true
        }
      })
      .then((resultSet) => {
        setResultSet(resultSet);
      }).catch((error) => {
        toast.error(error)
        if(error.message === 'Invalid token') {
            setCubeToken(false)
            localStorage.setItem("cubeToken", null);
            refresh()
        }
      }).finally(() => {
        setIsLoaderVisible(false) 
      });

    }
  }, [cubeQueryFilters,cubeQueryTimeDimensions,cubeToken]);


  function avgResultSet(cubeResult) {
    const date1 = new Date(calendarDate[0].startDate);
    const date2 = new Date(calendarDate[0].endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))+1;

    
    const divide = ['ams_sp_traffic.TotalOrderItemCount','ams_sp_traffic.clicks','ams_sp_traffic.conversions14d','ams_sp_traffic.cost','ams_sp_traffic.impressions','ams_sp_traffic.sales14d']
    const formulate = ['ams_sp_traffic.acos','ams_sp_traffic.cpc','ams_sp_traffic.cpm','ams_sp_traffic.ctr','ams_sp_traffic.roas','ams_sp_traffic.average_order_value']
    let data = cubeResult?.loadResponse?.results[0]?.data
    
const transformedData = data.map(obj => {
  const transformedObj = {};
  for (const key in obj) {
    if (divide.includes(key)) {
      transformedObj[key] = (parseFloat(obj[key]) / diffDays)
    } else if (formulate.includes(key)) {
      const sales14d = parseFloat(transformedObj['ams_sp_traffic.sales14d']);
      const cost = parseFloat(transformedObj['ams_sp_traffic.cost']);
      const clicks = parseFloat(transformedObj['ams_sp_traffic.clicks']);
      const impressions = parseFloat(transformedObj['ams_sp_traffic.impressions']);
      const conversions14d = parseFloat(transformedObj['ams_sp_traffic.conversions14d']);
      switch (key) {
        case 'ams_sp_traffic.roas':
          transformedObj[key] = sales14d >= 1 ? (sales14d / cost).toFixed(2) : 0;
          break;
        case 'ams_sp_traffic.acos':
          transformedObj[key] = sales14d >= 1 ? ((cost / sales14d) * 100).toFixed(2) :0;
          break;
        case 'ams_sp_traffic.cpc':
          transformedObj[key] = (cost / clicks).toFixed(3);
          break;
        case 'ams_sp_traffic.cpm':
          transformedObj[key] = ((cost * 1000) / impressions).toFixed(2);
          break;
        case 'ams_sp_traffic.ctr':
          transformedObj[key] = ((clicks / impressions) * 100).toFixed(2);
          break;
        case 'ams_sp_traffic.average_order_value':
          transformedObj[key] = sales14d >= 1 && conversions14d >= 1 ? (sales14d / conversions14d).toFixed(0) : 0;
          break;
        default:
          transformedObj[key] = obj[key];
      }
    } else {
      transformedObj[key] = obj[key];
    }
  }
  return transformedObj;
});
  return transformedData;
  } 
  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  function formatPieChartData(result,type,key) {
    if(Object.keys(result).length > 0) {
    let cubeResult = JSON.parse(JSON.stringify(result))
    let returnResultLabel = [];
    let returnResultSeries = [];
    let data = cubeResult?.loadResponse?.results[0]?.data
    data.map((value, index) => {
            if(value['ams_sp_traffic.hour'] == null ) {
                returnResultLabel.push("NULL")
            } else {
                returnResultLabel.push(value['ams_sp_traffic.hour'])
            }
            returnResultSeries.push(value['ams_sp_traffic.'+type])
    });
    let cubeResultLine = JSON.parse(JSON.stringify(resultSetComparison))
    let cubeLineData = cubeResultLine?.loadResponse?.results[0]?.data
    let returnResultLineLabel = [];
    let returnResultLineSeries = [];
    if(cubeLineData) {
      cubeLineData.map((value, index) => {
        if(value['ams_sp_traffic.hour'] == null ) {
          returnResultLineLabel.push("NULL")
        } else {
          returnResultLineLabel.push(value['ams_sp_traffic.hour'])
        }
        returnResultLineSeries.push(value['ams_sp_traffic.'+type])
      });
    }
    
    return {"labels":returnResultLabel,"series":returnResultSeries,"label":type,"key":key,"line": returnResultLineSeries, "lineLabel": returnResultLineLabel};
    } else {
        
    }
}

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={isLoaderVisible}></Loader>
      <div className="marketing-stream d-flex flex-column w-100 topDiv p-4">
        <div className="marketing-header d-flex justify-content-between align-items-center bg-white w-100 p-4 gap-4">
        <div><h2>Amazon Marketing Stream </h2></div>
          <div>
            <div
              style={{
                marginLeft: "auto",
              }}
              // className="d-flex justify-content-end align-item-end"
            >
              <button
                className="date-container date-btn-campaignlist d-none"
                onClick={dateInputClickHandler}
              >
                <div className="d-flex justify-conntent-center">
                  <span className="date-btn-text">{dateDifference}</span>
                </div>
                <button className="btn-calender-img-holder">
                  <span className="">
                    <img
                      className="dropdown-img-for-select"
                      src={selectSideIcon}
                      alt={"SelectSideIcon"}
                    ></img>

                    <img
                      className="calender-icon-for-campaignlist"
                      src={CalenderIconForCampaignList}
                      alt={"CalendarIcon"}
                    ></img>
                  </span>
                </button>
              </button>
            </div>
            <div
              ref={calenderIconRefForCampaignlist}
              className={
                showCustomDateBox ? "calender-inclasslist-container" : "hide"
              }
            >
              <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
              <span className="calender-close-icon-campaignlist">
                <img
                  onClick={() => setShowCustomDateBox(false)}
                  src={closeIcon}
                  alt={"CloseIcon"}
                ></img>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-8 w-100 p-4">
              <div className="campaign-list-table-data  w-100 p-3 bg-white rounded">
                <h6>Cumulative Weekly Performance Trend by Hour</h6>
                <Stack className="">
                    <Chart type="table" page={'marketing-stream'} resultSet={resultSet} classes={"noFilters marketing-reports"} shimmerEffect={isLoaderVisible} percentageShow={false} customDownloadURL={process.env.REACT_APP_MARKETING_CUBE_JS_URL}/>
                </Stack>
            </div>
            <div className="d-flex justify-content-between mt-4 mb-4 gap-4">
                <div  className="w-100 min-h-300 pie-container px-2">
                  <div className="px-2 pt-2">CPC</div>
                  <Chart type="customBar" resultSet={cpcResultSet} classes={"marketing-reports"} />
                </div>
                <div className="w-100 min-h-300 pie-container px-2">
                  <div className="px-2 pt-2">Conversion rate</div> 
                  <Chart type="customBar" resultSet={conversionRateResultSet} classes={"marketing-reports"} />
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4 mb-4 gap-4">
                <div  className="w-100 min-h-300 pie-container px-2">
                    <div className="px-2 pt-2">Cost</div>
                    <Chart type="customBar" resultSet={costResultSet} classes={"marketing-reports"} />
                </div>
                <div className="w-100 min-h-300 pie-container px-2">
                  <div className="px-2 pt-2">ACOS</div>
                  <Chart type="customBar" resultSet={acosResultSet} classes={"marketing-reports"} />
                </div>

            </div>
            <div className="d-flex justify-content-between mt-4 mb-4 gap-4">
                <div  className="w-100 min-h-300 pie-container px-2">
                  <div className="px-2 pt-2">Impressions</div>
                  <Chart type="customBar" resultSet={impressionsResultSet} classes={"marketing-reports"} />
                </div>
                <div className="w-100 min-h-300 pie-container px-2">
                    <div className="px-2 pt-2">Clicks</div>
                    <Chart type="customBar" resultSet={clicksResultSet} classes={"marketing-reports"} />
                </div>
            </div>
            {/* <div className="marketing-stream-container campaign-list-table-data  w-100 p-3 bg-white rounded">
                <h6>Marketing Stream by Campaign Overview</h6>
                <Stack className="campaign-list-main-table">
                    <Chart type="table" resultSet={resultSet} classes={'marketing-stream'} percentageShow={false}/>
                </Stack>
            </div> */}
            {/* <div className="d-flex justify-content-between mt-4 mb-4 gap-2">
            <div className="marketing-stream-container campaign-list-table-data  w-50 p-3 bg-white rounded">
                <h6>CPC</h6>
                <Stack className="campaign-list-main-table">
                    <Chart type="table" resultSet={resultSet} classes={'marketing-stream'} percentageShow={false}/>
                </Stack>
            </div>
            <div className="marketing-stream-container campaign-list-table-data w-50 p-3 bg-white rounded">
                <h6>ACOS</h6>
                <Stack className="campaign-list-main-table">
                    <Chart type="table" resultSet={resultSet} classes={'marketing-stream'} percentageShow={false}/>
                </Stack>
            </div>
            </div> */}
            {/* <div className="marketing-stream-container campaign-list-table-data  w-100 p-3 bg-white rounded">
                <h6>Raw Data</h6>
                <Stack className="campaign-list-main-table">
                    <Chart type="table" resultSet={resultSet} classes={'raw marketing-stream'} percentageShow={false}/>
                </Stack>
            </div> */}
        </div>
        
      </div>
    </div>
  );
};

export default MarketingStream;
