import SpinnerShimmer from "../../components/spinners/spinner";

export const generateShimmerTable = (numRows, numColumns) => {
    return Array.from({ length: numRows }, (_, rowIndex) => (
      <tr key={`shimmer-row-${rowIndex}`} className="td-with-loader">
        {Array.from({ length: numColumns }, (_, colIndex) => (
          <td key={`shimmer-col-${rowIndex}-${colIndex}`}>
            <SpinnerShimmer shimmer={true} />
          </td>
        ))}
      </tr>
    ));
  };