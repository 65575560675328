import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import CustomPagination from "../CustomPagination/CustomPagination";
const TableRowWithPagination = ({ rows, limit,colSpanLength }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * limit;
  const indexOfFirstRow = indexOfLastRow - limit;
  const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow) ?? []
  const {isNext,isPrev,totalPages} = getPaginationInfo(rows.length,limit,currentPage)
  function getPaginationInfo(totalNumber, limit, pageNumber) {
    // Calculate total number of pages
    const totalPages = Math.ceil(totalNumber / limit);
    // Determine if there's a next page
    const isNext = pageNumber < totalPages;
    // Determine if there's a previous page
    const isPrev = pageNumber > 1;
    return {
        isNext: isNext,
        isPrev: isPrev,
        totalPages: totalPages
    };
}
 
  const handlePageChange = (pageNumber) => {
    // Simulate an API call
    setCurrentPage(pageNumber);
  };
  return (
    <>
      {currentRows}
      <tr>
        <td colSpan={colSpanLength} >
            <div className="d-flex align-items-end gap-1 justify-content-center">
            <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                   />
            </div>
            </td>
      </tr>
    </>
  );
};
export default TableRowWithPagination;