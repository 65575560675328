import React, { useState, useEffect } from "react";
// import { Tooltip, Modal, Select, Badge, CalloutCard, Spinner, Checkbox } from "@shopify/polaris";
import styles from "./Widget.module.css";
import {
  catalogRulesFieldData,
  getCurrentDateTimeString,
  filterOperators,
} from "./ComplexFilters";
import AndOrLogic from "./subComponents/AndOrLogic/andorlogic";
import ReactSelect from "react-select";
import { reverseTransformToDesiredFormat } from "../../helper/widget";
import CalloutCard from "./subComponents/CalloutCard/CalloutCard";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import CustomTooltip from "./subComponents/CustomTooltip/CustomTooltip";
import CirclePlusIcon from "../../assets/svgs/circle_plus.svg";
import RightArrowIcon from "../../assets/icons/right-arrow.svg";
import RightArrowWhiteIcon from "../../assets/icons/right-arrow-white.svg";

import CustomCheckbox from "./subComponents/CustomCheckbox/CustomCheckbox";
const FilterWidget = (props) => {
  const [selectedFilter, setSelectedFilter] = useState("metrics");
  const [validationErrors, setValidationErrors] = useState([]);
  const [metricsGroupCondition, setMetricsGroupCondition] = useState("AND");
  const [metricsGroupConditionContents, setMetricsGroupConditionContents] =
    useState([]);
  const [dimensionsSummary, setDimensionsSummary] = useState([]);
  const [topXSummary, setTopXSummary] = useState([]);
  const [metricsSummary, setMetricsSummary] = useState([]);
  const [dimensionsGroupCondition, setDimensionsGroupCondition] =
    useState("AND");
  const [
    dimensionsGroupConditionContents,
    setDimensionsGroupConditionContents,
  ] = useState([]);
  const [
    campaignAmazonGroupConditionContents,
    setCampaignAmazonGroupConditionContents,
  ] = useState([]);
  const [
    campaignGoogleGroupConditionContents,
    setCampaignGoogleGroupConditionContents,
  ] = useState([]);
  const [campaignAmazonGroupCondition, setCampaignAmazonGroupCondition] =
    useState("AND");
  const [campaignGoogleGroupCondition, setCampaignGoogleGroupCondition] =
    useState("AND");
  const [campaignAmazonSummary, setCampaignAmazonSummary] = useState([]);
  const [campaignGoogleSummary, setCampaignGoogleSummary] = useState([]);
  const [showCampaignFilter, setShowCampaignFilter] = useState(false);

  const [isApplied, setIsApplied] = useState();
  const [isSidebar, setIsSidebar] = useState(false);
  const [topXFilter, setTopXFilter] = useState([]);
  const [showTopXFilter, setShowTopXFilter] = useState(false);
  const [selectedDay, setSelectedDay] = useState(props?.selectedDay);

  useEffect(() => {
    setShowCampaignFilter(props?.campaignFilter);
  }, [props?.campaignFilter]);
  useEffect(() => {
    setIsSidebar(props?.sidebar ?? true);
  }, [props?.sidebar]);

  useEffect(() => {
    setSelectedDay(props?.selectedDay);
  }, [props?.selectedDay]);

  useEffect(() => {
    setSelectedFilter(props?.default ?? "metrics");
  }, [props?.default]);

  // useEffect(()=> {
  //   document.getElementById('FilterBodySection').scrollIntoView({ behavior: 'smooth' });
  // },[selectedFilter])

  const addGroupConditionContent = (groupType) => {
    const groupConditions = {
      metrics: setMetricsGroupConditionContents,
      dimensions: setDimensionsGroupConditionContents,
      campaignAmazon: setCampaignAmazonGroupConditionContents,
      campaignGoogle: setCampaignGoogleGroupConditionContents,
    };

    if (groupConditions[groupType]) {
      groupConditions[groupType]((prevContents) => [
        ...prevContents,
        {
          id: getCurrentDateTimeString(),
          data: [
            {
              id: getCurrentDateTimeString(),
              category: "",
              filter: "",
              userInput: "",
            },
          ],
          operator: "AND",
        },
      ]);
    }

    setIsApplied(false);
  };

  const removeGroupConditionContent = (idToRemove, groupType) => {
    const groupSetters = {
      metrics: setMetricsGroupConditionContents,
      dimensions: setDimensionsGroupConditionContents,
      campaignAmazon: setCampaignAmazonGroupConditionContents,
      campaignGoogle: setCampaignGoogleGroupConditionContents,
      topX: setTopXFilter,
    };

    const setGroupContents = groupSetters[groupType];

    if (setGroupContents) {
      setGroupContents((prevContents) =>
        prevContents.filter((content) => content.id !== idToRemove)
      );
      setIsApplied(false);
    }
  };

  const updateGroupConditionContent = (
    index,
    updatedData,
    toUpdate,
    groupType
  ) => {
    const groupSetters = {
      metrics: setMetricsGroupConditionContents,
      dimensions: setDimensionsGroupConditionContents,
      campaignAmazon: setCampaignAmazonGroupConditionContents,
      campaignGoogle: setCampaignGoogleGroupConditionContents,
      topX: setTopXFilter,
    };

    const setGroupContents = groupSetters[groupType];

    if (setGroupContents) {
      setGroupContents((prevContents) =>
        prevContents.map((content) => {
          if (content.id === index) {
            return {
              ...content,
              [toUpdate]: updatedData,
            };
          }
          return content;
        })
      );
    }
  };

  useEffect(() => {
    if (showTopXFilter) {
      setTopXFilter((prevContents) => {
        if (prevContents.length === 0) {
          return [
            {
              id: getCurrentDateTimeString(),
              data: [
                {
                  id: getCurrentDateTimeString(),
                  category: "",
                  filter: "",
                  userInput: "",
                },
              ],
              operator: "AND",
            },
          ];
        } else {
          // If prevContents is not blank, return it unchanged
          return prevContents;
        }
      });
    } else {
      setTopXFilter([]);
    }
  }, [showTopXFilter]);

  //need to work on this
  useEffect(() => {
    let topXreversedData = [];
    let metricreversedData = [];
    let dimensionreversedData = [];
    let campaignAmazonReversedData = [];
    let campaignGoogleReversedData = [];

    if (props?.filters) {
      setMetricsSummary([]);
      setDimensionsSummary([]);
      setTopXSummary([]);
      setCampaignAmazonSummary([]);
      setCampaignGoogleSummary([]);
      if (props?.filters?.metrics?.custom_output) {
        metricreversedData = reverseTransformToDesiredFormat(
          props.filters.metrics.custom_output,
          props.config.measure
        );
        if (metricreversedData.length > 0) {
          setMetricsGroupConditionContents(metricreversedData);
          setMetricsGroupCondition(
            props.filters.metrics.custom_output.type === "OR_RULE"
              ? "OR"
              : "AND"
          );
        } else {
          setMetricsGroupConditionContents([]);
        }
      }

      if (props?.filters?.dimension?.custom_output) {
        dimensionreversedData = reverseTransformToDesiredFormat(
          props.filters.dimension.custom_output,
          props.config.dimension
        );
        if (dimensionreversedData.length > 0) {
          setDimensionsGroupConditionContents(dimensionreversedData);
          setDimensionsGroupCondition(
            props.filters.dimension.custom_output.type === "OR_RULE"
              ? "OR"
              : "AND"
          );
        } else {
          setDimensionsGroupConditionContents([]);
        }
      }
      if (props?.filters?.campaignAmazon?.custom_output) {
        campaignAmazonReversedData = reverseTransformToDesiredFormat(
          props.filters.campaignAmazon.custom_output,
          props.config.campaignAmazon
        );
        if (campaignAmazonReversedData.length > 0) {
          setCampaignAmazonGroupConditionContents(campaignAmazonReversedData);
          setCampaignAmazonGroupCondition(
            props.filters.campaignAmazon.custom_output.type === "OR_RULE"
              ? "OR"
              : "AND"
          );
        } else {
          setCampaignAmazonGroupConditionContents([]);
        }
      }

      if (props?.filters?.campaignGoogle?.custom_output) {
        campaignGoogleReversedData = reverseTransformToDesiredFormat(
          props.filters.campaignGoogle.custom_output,
          props.config.campaignGoogle
        );
        if (campaignGoogleReversedData.length > 0) {
          setCampaignGoogleGroupConditionContents(campaignGoogleReversedData);
          setCampaignGoogleGroupCondition(
            props.filters.campaignGoogle.custom_output.type === "OR_RULE"
              ? "OR"
              : "AND"
          );
        } else {
          setCampaignGoogleGroupConditionContents([]);
        }
      }

      if (props?.filters?.topX?.custom_output) {
        topXreversedData = reverseTransformToDesiredFormat(
          props.filters.topX.custom_output,
          props.config.topXConfig
        );
        if (topXreversedData.length > 0) {
          setShowTopXFilter(true);
          setTopXFilter(topXreversedData);
        }
      } else {
        setShowTopXFilter(false);
        setTopXFilter([]);
      }
    }
    if (
      (topXreversedData.length > 0 ||
        metricreversedData.length > 0 ||
        dimensionreversedData.length > 0 ||
        campaignAmazonReversedData.length > 0 ||
        campaignGoogleReversedData.length > 0) &&
      isApplied === undefined &&
      props?.summary === "onApplied"
    ) {
      setIsApplied(true);
      props.isApplied(true);
    } else if (
      !(
        topXreversedData.length > 0 ||
        metricreversedData.length > 0 ||
        dimensionreversedData.length > 0 ||
        campaignAmazonReversedData.length > 0 ||
        campaignGoogleReversedData.length > 0
      ) &&
      isApplied === true &&
      props?.summary === "onApplied"
    ) {
      setIsApplied(false);
      props.isApplied(true);
    }
  }, [props?.filters, props?.summary]);

  const checkIsEmptyAllSection = () => {
    if (
      dimensionsGroupConditionContents.length > 0 ||
      metricsGroupConditionContents.length > 0 ||
      topXFilter.length > 0 ||
      campaignAmazonGroupConditionContents.length > 0 ||
      campaignGoogleGroupConditionContents.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateConditions = (groupConditionContents, config, type) => {
    const errors = [];
    groupConditionContents.forEach((group, groupIndex) => {
      const groupError = {};
      group.data.forEach((condition, conditionIndex) => {
        const conditionErrors = [];
        if (!condition.category) {
          conditionErrors.push({ category: "Category is required." });
        }
        if (!condition.filter) {
          conditionErrors.push({ filter: "Filter is required." });
        }
        if (
          condition.filter === "Not Equals" &&
          type === "dimension" &&
          !props.checkValidationForAllOperators
        ) {
        } else if (
          condition.filter === "Exist" ||
          condition.filter === "Not Exist"
        ) {
        } else if (condition.userInput.length > 0 && type === "topX") {
          const userInputNum = Number(condition.userInput[0].trim());
          if (isNaN(userInputNum)) {
            conditionErrors.push({
              userInput: "Please enter a numeric value.",
            });
          } else if (userInputNum < 1 || userInputNum > 99) {
            conditionErrors.push({
              userInput: "Please enter proper input value",
            });
          }
        } else {
          if (
            condition.userInput.length === 0 ||
            (typeof condition.userInput[0] === "string" &&
              condition.userInput[0].trim() === "") ||
            condition.userInput[0] == null
          ) {
            conditionErrors.push({ userInput: "User input is required." });
          }
        }
        if (conditionErrors.length > 0) {
          groupError[condition.id] = conditionErrors;
        }
      });
      if (Object.keys(groupError).length > 0) {
        errors.push(groupError);
      }
    });
    return errors;
  };

  const handleSave = async () => {
    const errorMap = {
      metrics: validateConditions(
        metricsGroupConditionContents,
        props.config.measure,
        "measure"
      ),
      dimensions: validateConditions(
        dimensionsGroupConditionContents,
        props.config.dimension,
        "dimension"
      ),
      campaignAmazon: validateConditions(
        campaignAmazonGroupConditionContents,
        props.config.campaignAmazon,
        "campaignAmazon"
      ),
      campaignGoogle: validateConditions(
        campaignGoogleGroupConditionContents,
        props.config.campaignGoogle,
        "campaignGoogle"
      ),
      topX: validateConditions(topXFilter, props.config.topXConfig, "topX"),
    };
    const allErrors = Object.values(errorMap).flat(); // Flatten all error arrays
    if (allErrors.length > 0) {
      setValidationErrors(allErrors);

      if (errorMap[selectedFilter]?.length > 0) {
        return; // Stay on the current tab if it has errors
      }
      for (const [filter, errors] of Object.entries(errorMap)) {
        if (errors.length > 0 && filter !== "topX") {
          setSelectedFilter(filter);
          break;
        }
      }
      return;
    } else {
      setValidationErrors([]);

      const metricsTransformedOutput = transformGroupCondition(
        metricsGroupConditionContents,
        metricsGroupCondition,
        props.config.measure,
        "measure"
      );
      const metricsCustomOutput = {
        type: metricsGroupCondition.toUpperCase() + "_RULE",
        [metricsGroupCondition.toLowerCase()]: metricsTransformedOutput,
      };

      const dimensionsTransformedOutput = transformGroupCondition(
        dimensionsGroupConditionContents,
        dimensionsGroupCondition,
        props.config.dimension,
        "dimension"
      );
      const dimensionsCustomOutput = {
        type: dimensionsGroupCondition.toUpperCase() + "_RULE",
        [dimensionsGroupCondition.toLowerCase()]: dimensionsTransformedOutput,
      };

      const topXTransformedOutput = transformGroupCondition(
        topXFilter,
        "AND",
        props.config.topXConfig,
        "topX"
      );
      const topXCustomOutput = {
        type: "AND_RULE",
        ["and"]: topXTransformedOutput,
      };

      const campaignAmazonTransformedOutput = transformGroupCondition(
        campaignAmazonGroupConditionContents,
        campaignAmazonGroupCondition,
        props.config.campaignAmazon,
        "campaignAmazon"
      );
      const campaignAmazonCustomOutput = {
        type: campaignAmazonGroupCondition.toUpperCase() + "_RULE",
        [campaignAmazonGroupCondition.toLowerCase()]:
          campaignAmazonTransformedOutput,
      };

      const campaignGoogleTransformedOutput = transformGroupCondition(
        campaignGoogleGroupConditionContents,
        campaignGoogleGroupCondition,
        props.config.campaignGoogle,
        "campaignGoogle"
      );
      const campaignGoogleCustomOutput = {
        type: campaignGoogleGroupCondition.toUpperCase() + "_RULE",
        [campaignGoogleGroupCondition.toLowerCase()]:
          campaignGoogleTransformedOutput,
      };

      let output = {
        metrics: {
          custom_output: metricsTransformedOutput.length
            ? metricsCustomOutput
            : [],
          operator: metricsGroupCondition,
          data: metricsGroupConditionContents,
        },
        dimension: {
          custom_output:
            dimensionsTransformedOutput.length > 0
              ? dimensionsCustomOutput
              : [],
          operator: dimensionsGroupCondition,
          data: dimensionsGroupConditionContents,
        },
        campaignAmazon: {
          custom_output:
            campaignAmazonTransformedOutput.length > 0
              ? campaignAmazonCustomOutput
              : [],
          operator: campaignAmazonGroupCondition,
          data: campaignAmazonGroupConditionContents,
        },
        campaignGoogle: {
          custom_output:
            campaignGoogleTransformedOutput.length > 0
              ? campaignGoogleCustomOutput
              : [],
          operator: campaignGoogleGroupCondition,
          data: campaignGoogleGroupConditionContents,
        },
        topX: {
          custom_output:
            topXTransformedOutput.length > 0 ? topXCustomOutput : [],
          operator: "AND",
          data: topXFilter,
        },
      };
      props.output(output);
      if (props?.summaryOutput) {
        props.summaryOutput(filterSummary());
      }
      if (props?.type === "popup") {
        props?.setShowPopupModal(false);
      }
      setTimeout(() => {
        setIsApplied(true);
      }, 500);
    }
  };

  function transformGroupCondition(
    groupConditionContents,
    groupCondition,
    config,
    type
  ) {
    const transformedGroupCondition = [];
    groupConditionContents.forEach((group) => {
      const conditions = (group.data || []).map((condition) => {
        const fieldConfig = config.fields.find(
          (field) =>
            field.name === condition.category || field.id === condition.category
        );
        const operatorConfig = config.operators[fieldConfig?.datatype].find(
          (field) => field.name === condition.filter
        );
        const conditionObject = {
          type: "RULE_ITEM",
          member: fieldConfig
            ? props.prefixMember + fieldConfig.id
            : condition.category,
          operator: operatorConfig ? operatorConfig.id : condition.filter,
          values:
            condition.userInput !== ""
              ? condition.userInput
              : fieldConfig?.datatype === "int"
              ? [0]
              : [""],
        };

        const innerKey = condition.filter.toLowerCase() === "or" ? "or" : "and";

        return conditionObject;
      });

      if (conditions.length > 0) {
        const outerKey = group.operator.toLowerCase();
        transformedGroupCondition.push({
          type: outerKey === "or" ? "OR_RULE" : "AND_RULE",
          [outerKey]: conditions,
        });
      }

      if (group.data) {
        const subgroups = transformGroupCondition(
          group.data,
          group.operator,
          config,
          type
        );
        if (subgroups.length > 0) {
          transformedGroupCondition.push(...subgroups);
        }
      }
    });
    return transformedGroupCondition;
  }

  function formatSummaryToLines(data, groupCondition) {
    const lines = [];
    data.forEach((group, index) => {
      // Check if group has data
      if (group.data && group.data.length > 0) {
        const conditions = group.data
          .map((condition) => {
            const userInput =
              condition?.userInput?.length > 0
                ? `${condition.userInput.join(", ")}`
                : "";
            return `${condition.category} ${condition.filter} ${userInput}`;
          })
          .join(` ${group.operator} `); // Added spaces around the operator

        lines.push(`${conditions}`);
      }

      // Add group condition (AND/OR) between groups, except after the last one
      if (index < data.length - 1 && group.data.length > 0) {
        lines.push(groupCondition);
      }
    });

    return lines;
  }

  useEffect(() => {
    setMetricsSummary(
      formatSummaryToLines(metricsGroupConditionContents, metricsGroupCondition)
    );
    setDimensionsSummary(
      formatSummaryToLines(
        dimensionsGroupConditionContents,
        dimensionsGroupCondition
      )
    );
    setCampaignGoogleSummary(
      formatSummaryToLines(
        campaignGoogleGroupConditionContents,
        campaignGoogleGroupCondition
      )
    );
    setCampaignAmazonSummary(
      formatSummaryToLines(
        campaignAmazonGroupConditionContents,
        campaignAmazonGroupCondition
      )
    );
    setTopXSummary(formatSummaryToLines(topXFilter, "AND"));
  }, [
    metricsGroupConditionContents,
    dimensionsGroupConditionContents,
    metricsGroupCondition,
    dimensionsGroupCondition,
    topXFilter,
    campaignGoogleGroupConditionContents,
    campaignAmazonGroupConditionContents,
    campaignAmazonGroupCondition,
    campaignGoogleGroupCondition,
  ]);

  const callOutFunction = (callOutType) => {
    return (
      <CalloutCard
        title={
          props?.calloutTitle
            ? props?.calloutTitle
            : selectedFilter === "metrics"
            ? "Select your product metrics"
            : selectedFilter === "campaignAmazon"
            ? "Select your campaign amazon attributes"
            : selectedFilter === "campaignGoogle"
            ? "Select your google campaign attributes"
            : "Select your product attributes"
        }
        // illustration="/filter-illustration.svg"
        primaryAction={{
          content: props?.calloutContent
            ? props?.calloutContent
            : selectedFilter === "metrics"
            ? "+ Add Metrics Filter"
            : selectedFilter === "campaignAmazon"
            ? "+ Add Campaign Amazon Filter"
            : selectedFilter === "campaignGoogle"
            ? "+ Add Campaign Google Filter"
            : "+ Add Dimension Filter",
          onClick: () => addGroupConditionContent(callOutType),
        }}
      >
        {props?.calloutDescription ? (
          <p>{props?.calloutDescription}</p>
        ) : (
          <p>
            {props?.page === "product-sets"
              ? selectedFilter === "metrics"
                ? "Customize your productset with a variety of advanced metrics"
                : "Customize your productset with a variety of product attributes"
              : props?.page === "stoploss"
              ? selectedFilter === "metrics"
                ? "Customize your stoploss with a variety of advanced metrics"
                : "Customize your stoploss with a variety of product attributes"
              : selectedFilter === "metrics"
              ? "Add new filters for your metrics to get more detailed insights."
              : selectedFilter === "dimension"
              ? "Add new filters for your dimensions to refine your data analysis."
              : selectedFilter === "campaignAmazon"
              ? "Add new campaign amazon filter to refine your data analysis."
              : selectedFilter === "campaignGoogle"
              ? "Add new campaign google filter to refine your data analysis."
              : "Add new Filter"}
          </p>
        )}
      </CalloutCard>
    );
  };

  const StyledBadge = ({ children, isOperator, type }) => {
    // Return the span only if children exist
    return children ? (
      <CustomTooltip
        content={
          <div
            style={{
              maxWidth: "400px",
              textAlign: "center",
              wordBreak: "break-word",
            }}
          >
            {children}
          </div>
        }
      >
        <div
          className={styles.lineClampAtTwo}
          style={{
            // display: 'inline-block',
            padding: isOperator ? "0px 10px" : "2px 10px",
            borderRadius: "10px",
            backgroundColor: "#FFF",
            fontWeight: isOperator ? "bold" : "normal",
            width: "fit-content",
            wordBreak: "break-all",
            fontSize: "14px",
          }}
        >
          {children}
        </div>
      </CustomTooltip>
    ) : null;
  };

  const renderSummarySection = (summary, type, title) => {
    if (summary.length === 0) return null;

    return (
      <div className={styles.sectionWrapper}>
        {isSidebar && <div className={styles.sectionTitle}>{title}</div>}
        <div className={styles.summaryContent}>
          {summary.flatMap((line, index) => {
            if (line.trim() === "") return null;
            const parts = line.split(/(\sAND|\sOR)/);
            return (
              <div
                key={index}
                className={styles.card}
                style={{ display: "flex", gap: "6px", width: "100%" }}
              >
                {parts.map((part, partIndex) => {
                  const isOperator = part === "AND" || part === "OR";
                  return (
                    <StyledBadge
                      key={`${index}-${partIndex}`}
                      isOperator={isOperator}
                      type={type}
                    >
                      {part.trim()}
                    </StyledBadge>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const filterSummary = () => {
    return (
      <div className={styles.cardWrappers}>
        {renderSummarySection(metricsSummary, "metrics", "Metric Filters")}
        {renderSummarySection(
          dimensionsSummary,
          "dimensions",
          "Attribute Filter"
        )}
        {renderSummarySection(
          campaignAmazonSummary,
          "campaignAmazon",
          "Amazon Campaign Filter"
        )}
        {renderSummarySection(
          campaignGoogleSummary,
          "campaignGoogle",
          "Google Campaign Filter"
        )}
        {renderSummarySection(
          topXSummary,
          "topX",
          "Narrow Down Filter Summary"
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      props?.isApplied &&
      isApplied !== undefined &&
      props?.summary === "onApplied" &&
      !checkIsEmptyAllSection
    ) {
      props.isApplied(isApplied);
    }
  }, [isApplied]);

  const topXLabel = () => {
    return (
      <div>
        <CustomTooltip
          width="wide"
          content={
            "This option lets you focus on products based on their performance in a specific metric. Enter a percentage (e.g., 20%) and choose a metric (revenue, spends, etc.) to see the top X% or bottom X% of products based on that metric."
          }
          dismissOnMouseOut
          padding={4}
        >
          <div style={{ display: "flex", gap: "4px" }}>
            <div>Narrow condition further</div>{" "}
            <div>
              <img
                src="/svg/tooltip.svg"
                alt={"tooltip"}
                style={{ paddingTop: "2px" }}
              />
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const htmlContent = (minHeightPx) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div
          style={{
            display:
              props.type === "page" &&
              props?.summary === "onApplied" &&
              isApplied
                ? "none"
                : "flex",
            gap: "16px",
          }}
        >
          {isSidebar && (
            <div className={styles.tabContent} style={{minHeight:minHeightPx?minHeightPx:"610px"}}>
              <div className={styles.tabWrapper}>
                <div className={styles.tabHeading}>Filters</div>
                <div style={{ padding: "4px 8px", marginTop: "12px" }}>
                  <div
                    className={`${
                      selectedFilter === "metrics"
                        ? styles.tabSelected
                        : styles.tab
                    } button_metrics`}
                    onClick={() => setSelectedFilter("metrics")}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", gap: "6px" }}>
                        <div> Metrics</div>
                        <div>
                          {metricsGroupConditionContents.length > 0 && (
                            <Badge bg="light" text="secondary">
                              Applied
                            </Badge>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            selectedFilter === "metrics"
                              ? RightArrowWhiteIcon
                              : RightArrowIcon
                          }
                          alt="right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "4px 8px" }}>
                  <div
                    className={`${
                      selectedFilter === "dimensions"
                        ? styles.tabSelected
                        : styles.tab
                    } button_dimensions`}
                    onClick={() => setSelectedFilter("dimensions")}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", gap: "6px" }}>
                        <div> Attributes</div>
                        <div>
                          {dimensionsGroupConditionContents.length > 0 && (
                            <Badge bg="light" text="secondary">
                              Applied
                            </Badge>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            selectedFilter === "dimensions"
                              ? RightArrowWhiteIcon
                              : RightArrowIcon
                          }
                          alt="right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showCampaignFilter && (
                  <div style={{ padding: "8px" }}>
                    <div
                      className={`${
                        selectedFilter === "campaignAmazon" ||
                        selectedFilter === "campaignGoogle"
                          ? styles.tabSelected
                          : styles.tab
                      } button_dimensions`}
                      onClick={() => setSelectedFilter("campaignAmazon")}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>Campaign</div>
                        <div
                          style={{
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              selectedFilter === "campaignAmazon" ||
                              selectedFilter === "campaignGoogle"
                                ? RightArrowWhiteIcon
                                : RightArrowIcon
                            }
                            alt="right"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: 1,
                        transition:
                          "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                        visibility: "visible",
                      }}
                    >
                      <div
                        style={{
                          padding: "12px 0px 12px 20px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {props.config?.campaignAmazon && (
                          <div
                            className={`${
                              selectedFilter === "campaignAmazon"
                                ? `${styles.tabSelected} ${styles.subTabSelected}`
                                : `${styles.tab} ${styles.subTab}`
                            } button_amazon`}
                            onClick={() => setSelectedFilter("campaignAmazon")}
                          >
                            <div>Amazon</div>
                            <div
                              style={{ minWidth: "54px", maxHeight: "20px" }}
                            >
                              {campaignAmazonGroupConditionContents.length >
                                0 && (
                                <Badge bg="light" text="secondary">
                                  Applied
                                </Badge>
                              )}
                            </div>
                            <div
                              style={{
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  selectedFilter === "campaignAmazon"
                                    ? RightArrowWhiteIcon
                                    : RightArrowIcon
                                }
                                alt="right"
                              />
                            </div>
                          </div>
                        )}

                        {props.config?.campaignGoogle && (
                          <div
                            className={`${
                              selectedFilter === "campaignGoogle"
                                ? `${styles.tabSelected} ${styles.subTabSelected}`
                                : `${styles.tab} ${styles.subTab}`
                            } button_google`}
                            onClick={() => setSelectedFilter("campaignGoogle")}
                          >
                            <div>Google</div>
                            <div
                              style={{ minWidth: "54px", maxHeight: "20px" }}
                            >
                              {campaignGoogleGroupConditionContents.length >
                                0 && (
                                <Badge bg="light" text="secondary">
                                  Applied
                                </Badge>
                              )}
                            </div>
                            <div
                              style={{
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  selectedFilter === "campaignGoogle"
                                    ? RightArrowWhiteIcon
                                    : RightArrowIcon
                                }
                                alt="right"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={styles.filters}
            style={{ minWidth: "710px" }}
            id={"FilterBodySection"}
          >
            {selectedFilter === "metrics" ? (
              <div className={styles.filterWrapper} key={"metrics"}>
                {catalogRulesFieldData.map((data) => (
                  <div key={data.title}>
                    {metricsGroupConditionContents.length > 0 ? (
                      <>
                        {metricsGroupConditionContents.map((value, index) => (
                          <>
                            <AndOrLogic
                              key={value.id}
                              id={value.id}
                              data={value.data}
                              operator={value.operator}
                              updateGroupConditionContent={(
                                updatedValue,
                                toUpdate
                              ) => {
                                updateGroupConditionContent(
                                  value.id,
                                  updatedValue,
                                  toUpdate,
                                  "metrics"
                                );
                              }}
                              removeGroupConditionContent={() => {
                                removeGroupConditionContent(
                                  value.id,
                                  "metrics"
                                );
                              }}
                              showCloseButton={index === 0 ? true : true}
                              conditionError={validationErrors}
                              config={props.config.measure}
                              type={props.type}
                              userAction={(e) => {
                                setIsApplied(false);
                              }}
                              multiple={true}
                            />
                            {metricsGroupConditionContents.length > 1 &&
                              index <
                                metricsGroupConditionContents.length - 1 && (
                                <div
                                  style={{ width: "100px", margin: "8px auto" }}
                                >
                                  {/* <CustomSelect style={{ height: '24px' }} options={filterOperators} value={metricsGroupCondition} onChange={(value) => { setMetricsGroupCondition(value); setIsApplied(false) }} /> */}

                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="Select Operator"
                                    options={filterOperators}
                                    value={filterOperators?.find(
                                      (item) =>
                                        item.value === metricsGroupCondition
                                    )}
                                    onChange={(item) => {
                                      setMetricsGroupCondition(item.value);
                                      setIsApplied(false);
                                    }}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        height: "36px",
                                        fontSize: "14px",
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        maxHeight: "180px",
                                        overflow: "hidden",
                                        fontSize: "14px",
                                        zIndex: "99",
                                      }),
                                      menuList: (provided, state) => ({
                                        ...provided,
                                        fontSize: "14px",
                                        maxHeight: "150px", // Ensure the menu list also respects the max height
                                      }),
                                    }}
                                  />
                                </div>
                              )}
                          </>
                        ))}
                      </>
                    ) : (
                      <div>{callOutFunction("metrics")}</div>
                    )}
                    {metricsGroupConditionContents.length > 0 ? (
                      <div>
                        <div className={styles.groupCondition}>
                          <div
                            onClick={() => {
                              addGroupConditionContent("metrics");
                              setIsApplied(false);
                            }}
                            className={styles.Condition}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={CirclePlusIcon} alt="add" />
                            <p style={{ margin: "0" }}>Group Condition</p>
                          </div>
                        </div>
                        {props?.showDays &&
                          props?.showDays == true &&
                          selectedFilter === "metrics" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <span>In the last</span>
                              <ReactSelect
                                className="basic-single"
                                isSearchable={true}
                                options={props.daysOptions}
                                value={selectedDay}
                                onChange={props?.handleSelectedDay}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    height: "36px",
                                    fontSize: "14px",
                                  }),
                                  menu: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "180px",
                                    overflow: "hidden",
                                    fontSize: "14px",
                                    zIndex: "99",
                                  }),
                                  menuList: (provided, state) => ({
                                    ...provided,
                                    fontSize: "14px",
                                    maxHeight: "150px", // Ensure the menu list also respects the max height
                                  }),
                                }}
                              />
                            </div>
                          )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : selectedFilter === "dimensions" ? (
              <div className={styles.filterWrapper} key={"dimension"}>
                {catalogRulesFieldData.map((data) => (
                  <div key={data.Featuretitle}>
                    {dimensionsGroupConditionContents.length > 0 ? (
                      <>
                        {dimensionsGroupConditionContents.map(
                          (value, index) => (
                            <>
                              <AndOrLogic
                                key={value.id}
                                id={value.id}
                                data={value.data}
                                operator={value.operator}
                                updateGroupConditionContent={(
                                  updatedValue,
                                  toUpdate
                                ) => {
                                  updateGroupConditionContent(
                                    value.id,
                                    updatedValue,
                                    toUpdate,
                                    "dimensions"
                                  );
                                }}
                                removeGroupConditionContent={() => {
                                  removeGroupConditionContent(
                                    value.id,
                                    "dimensions"
                                  );
                                }}
                                showCloseButton={index === 0 ? true : true}
                                conditionError={validationErrors}
                                config={props.config.dimension}
                                type={props.type}
                                userAction={(e) => setIsApplied(false)}
                                multiple={true}
                                inOperatorMaxValues={props.inOperatorMaxValues}
                              />
                              {dimensionsGroupConditionContents.length > 1 &&
                                index <
                                  dimensionsGroupConditionContents.length -
                                    1 && (
                                  <div
                                    style={{
                                      width: "100px",
                                      margin: "8px auto",
                                    }}
                                  >
                                    {/* <CustomSelect options={filterOperators} value={dimensionsGroupCondition} onChange={(value) => {
                                  setDimensionsGroupCondition(value)
                                  setIsApplied(false)
                                }} /> */}
                                    <ReactSelect
                                      className="basic-single"
                                      classNamePrefix="Select Operator"
                                      value={filterOperators?.find(
                                        (item) =>
                                          item.value ===
                                          dimensionsGroupCondition
                                      )}
                                      options={filterOperators}
                                      onChange={(item) => {
                                        setDimensionsGroupCondition(item.value);
                                        setIsApplied(false);
                                      }}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          height: "36px",
                                          fontSize: "14px",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          maxHeight: "180px",
                                          overflow: "hidden",
                                          fontSize: "14px",
                                          zIndex: "99",
                                        }),
                                        menuList: (provided, state) => ({
                                          ...provided,
                                          fontSize: "14px",
                                          maxHeight: "150px", // Ensure the menu list also respects the max height
                                        }),
                                      }}
                                    />
                                  </div>
                                )}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <div>{callOutFunction("dimensions")}</div>
                    )}
                    {dimensionsGroupConditionContents.length > 0 ? (
                      <div>
                        <div className={styles.groupCondition}>
                          <div
                            onClick={() => {
                              addGroupConditionContent("dimensions");
                              setIsApplied(false);
                            }}
                            className={styles.Condition}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={CirclePlusIcon} alt="add" />
                            <p style={{ margin: "0" }}>Group Condition</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : selectedFilter === "campaignAmazon" ? (
              <div className={styles.filterWrapper} key={"campaignAmazon"}>
                {catalogRulesFieldData.map((data) => (
                  <div key={data.Featuretitle}>
                    {campaignAmazonGroupConditionContents.length > 0 ? (
                      <>
                        {campaignAmazonGroupConditionContents.map(
                          (value, index) => (
                            <>
                              <AndOrLogic
                                key={value.id}
                                id={value.id}
                                data={value.data}
                                operator={value.operator}
                                updateGroupConditionContent={(
                                  updatedValue,
                                  toUpdate
                                ) => {
                                  updateGroupConditionContent(
                                    value.id,
                                    updatedValue,
                                    toUpdate,
                                    "campaignAmazon"
                                  );
                                }}
                                removeGroupConditionContent={() => {
                                  removeGroupConditionContent(
                                    value.id,
                                    "campaignAmazon"
                                  );
                                }}
                                showCloseButton={index === 0 ? true : true}
                                conditionError={validationErrors}
                                config={props.config.campaignAmazon}
                                type={props.type}
                                userAction={(e) => setIsApplied(false)}
                                multiple={true}
                              />
                              {campaignAmazonGroupConditionContents.length >
                                1 &&
                                index <
                                  campaignAmazonGroupConditionContents.length -
                                    1 && (
                                  <div
                                    style={{
                                      width: "100px",
                                      margin: "8px auto",
                                    }}
                                  >
                                    <ReactSelect
                                      className="basic-single"
                                      classNamePrefix="Select Operator"
                                      value={filterOperators?.find(
                                        (item) =>
                                          item.value ===
                                          campaignAmazonGroupCondition
                                      )}
                                      options={filterOperators}
                                      onChange={(item) => {
                                        setCampaignAmazonGroupCondition(item.value);
                                        setIsApplied(false);
                                      }}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          height: "36px",
                                          fontSize: "14px",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          maxHeight: "180px",
                                          overflow: "hidden",
                                          fontSize: "14px",
                                          zIndex: "99",
                                        }),
                                        menuList: (provided, state) => ({
                                          ...provided,
                                          fontSize: "14px",
                                          maxHeight: "150px", // Ensure the menu list also respects the max height
                                        }),
                                      }}
                                    />
                                  </div>
                                )}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <div>{callOutFunction("campaignAmazon")}</div>
                    )}
                    {campaignAmazonGroupConditionContents.length > 0 ? (
                      <div>
                        <div className={styles.groupCondition}>
                          <div
                            onClick={() => {
                              addGroupConditionContent("campaignAmazon");
                              setIsApplied(false);
                            }}
                            className={styles.Condition}
                            style={{ cursor: "pointer" }}
                          >
                            <img src="/catalog/circle plus.svg" alt="" />
                            <p>Group Condition</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : selectedFilter === "campaignGoogle" ? (
              <div className={styles.filterWrapper} key={"campaignGoogle"}>
                {catalogRulesFieldData.map((data) => (
                  <div key={data.Featuretitle}>
                    {campaignGoogleGroupConditionContents.length > 0 ? (
                      <>
                        {campaignGoogleGroupConditionContents.map(
                          (value, index) => (
                            <>
                              <AndOrLogic
                                key={value.id}
                                id={value.id}
                                data={value.data}
                                operator={value.operator}
                                updateGroupConditionContent={(
                                  updatedValue,
                                  toUpdate
                                ) => {
                                  updateGroupConditionContent(
                                    value.id,
                                    updatedValue,
                                    toUpdate,
                                    "campaignGoogle"
                                  );
                                }}
                                removeGroupConditionContent={() => {
                                  removeGroupConditionContent(
                                    value.id,
                                    "campaignGoogle"
                                  );
                                }}
                                showCloseButton={index === 0 ? true : true}
                                conditionError={validationErrors}
                                config={props.config.campaignGoogle}
                                type={props.type}
                                userAction={(e) => setIsApplied(false)}
                                multiple={true}
                              />
                              {campaignGoogleGroupConditionContents.length >
                                1 &&
                                index <
                                  campaignGoogleGroupConditionContents.length -
                                    1 && (
                                  <div
                                    style={{
                                      width: "100px",
                                      margin: "8px auto",
                                    }}
                                  >
                                    <ReactSelect
                                      className="basic-single"
                                      classNamePrefix="Select Operator"
                                      value={filterOperators?.find(
                                        (item) =>
                                          item.value ===
                                          campaignGoogleGroupCondition
                                      )}
                                      options={filterOperators}
                                      onChange={(item) => {
                                        setCampaignGoogleGroupCondition(item.value);
                                        setIsApplied(false);
                                      }}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          height: "36px",
                                          fontSize: "14px",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          maxHeight: "180px",
                                          overflow: "hidden",
                                          fontSize: "14px",
                                          zIndex: "99",
                                        }),
                                        menuList: (provided, state) => ({
                                          ...provided,
                                          fontSize: "14px",
                                          maxHeight: "150px", // Ensure the menu list also respects the max height
                                        }),
                                      }}
                                    />
                                  </div>
                                )}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <div>{callOutFunction("campaignGoogle")}</div>
                    )}
                    {campaignGoogleGroupConditionContents.length > 0 ? (
                      <div>
                        <div className={styles.groupCondition}>
                          <div
                            onClick={() => {
                              addGroupConditionContent("campaignGoogle");
                              setIsApplied(false);
                            }}
                            className={styles.Condition}
                            style={{ cursor: "pointer" }}
                          >
                            <img src="/catalog/circle plus.svg" alt="" />
                            <p>Group Condition</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
            {props?.narrowFilters && (
              <div key={"top"}>
                <div>
                  <CustomCheckbox
                    label={topXLabel()}
                    checked={showTopXFilter}
                    onChange={(e) => setShowTopXFilter(e)}
                  />
                </div>
                <div>
                  {showTopXFilter ? (
                    <div>
                      {topXFilter.map((value, index) => (
                        <AndOrLogic
                          key={index}
                          id={value.id}
                          data={value.data}
                          operator={value.operator}
                          updateGroupConditionContent={(
                            updatedValue,
                            toUpdate
                          ) => {
                            updateGroupConditionContent(
                              value.id,
                              updatedValue,
                              toUpdate,
                              "topX"
                            );
                          }}
                          removeGroupConditionContent={() => {
                            removeGroupConditionContent(value.id, "topX");
                          }}
                          showCloseButton={false}
                          conditionError={validationErrors}
                          config={props.config.topXConfig}
                          type={props.type}
                          userAction={(e) => {
                            setIsApplied(false);
                          }}
                          multiple={false}
                        />
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {(metricsGroupConditionContents.length > 0 ||
              dimensionsGroupConditionContents.length > 0 ||
              topXSummary.length > 0 ||
              campaignGoogleSummary?.length > 0 ||
              campaignAmazonSummary.length > 0) &&
            props.type === "popup" ? (
              <div className={styles.filterWrappers}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    padding: "12px",
                    width: "100%",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Applied Filter Summary
                  </div>
                  <div>
                    {dimensionsSummary.length > 0 ||
                    metricsSummary.length > 0 ||
                    topXSummary.length > 0
                      ? filterSummary()
                      : null}
                  </div>
                </div>
              </div>
            ) : null}
            {(metricsGroupConditionContents.length > 0 ||
              dimensionsGroupConditionContents.length > 0 ||
              topXSummary.length > 0 ||
              campaignGoogleGroupConditionContents?.length > 0 ||
              campaignAmazonGroupConditionContents.length > 0) &&
            props.type === "page" ? (
              <div
                className={styles.filterWrappers}
                style={{
                  display:
                    props.type === "page" &&
                    props?.summary === "onApplied" &&
                    !isApplied
                      ? "none"
                      : "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    padding: "12px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#637381",
                    }}
                  >
                    Filter Summary
                  </div>
                  <div>
                    {dimensionsSummary.length > 0 ||
                    metricsSummary.length > 0 ||
                    topXSummary.length > 0 ||
                    campaignGoogleSummary?.length > 0 ||
                    campaignAmazonSummary.length > 0
                      ? filterSummary()
                      : null}
                    {props?.showDays &&
                      props?.showDays == true &&
                      selectedFilter === "metrics" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            float: "right",
                            paddingTop: "16px",
                          }}
                        >
                          <span style={{ color: "#6d7175" }}>
                            * In the last {selectedDay?.label}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {props.type !== "popup" ? (
          <>
            {props?.editDisabled && props?.editDisabled == true ? (
              <></>
            ) : props.type === "page" &&
              props?.summary === "onApplied" &&
              isApplied ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={(e) => {
                    setIsApplied(false);
                  }}
                  className="button_apply_filter primary-active-btn"
                >
                  Edit filter
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  disabled={props.fetchingData || isApplied}
                  onClick={handleSave}
                  className="button_apply_filter primary-active-btn"
                  style={{
                    opacity: isApplied ? "0.4" : "1",
                  }}
                >
                  {props.fetchingData ? (
                    <>
                      <Spinner animation="border" size="sm" />{" "}
                    </>
                  ) : isApplied ? (
                    "Applied"
                  ) : (
                    "Apply"
                  )}
                </button>
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  if (props.type === "popup") {
    return (
      <Modal
        dialogClassName={styles.popupModal}
        centered={true}
        show={props?.showPopupModal}
        animation={true}
        scrollable={true}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>{htmlContent()}</Modal.Body>
        <Modal.Footer>
          <button
            className="secondary-active-btn"
            onClick={() => {
              props?.setShowPopupModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="primary-active-btn"
            onClick={() => {
              handleSave();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <div className={`${styles.pageContent} ${styles[props?.page]}`}>
        {htmlContent("auto")}
      </div>
    );
  }
};
export default FilterWidget;
