import { Modal } from "react-bootstrap";
import styles from "./StopLossLogsModal.module.css";
import React, { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import TableRb from "../../../../components/Tables/tableRb";
import arrowUpIcon from "../../../../assets/icons/arrow_up_icon.svg";
import arrowDownIcon from "../../../../assets/icons/down_arrow.svg";
import axios from "axios";
import { useGlobalContext } from "../../../../context/global";
import FilterPreview from "./../../subComponents/FilterViewer/FilterViewer";
import moment from "moment";
import { getProductWithImageForLogs } from "../../../../components/Reports/Components/utils";
import { generateShimmerTable } from "../../../../helper/common/tableShimmerGenerator";
import Spinner from "../../../../components/spinners/spinner";
import { getCubeFiltersByActionTypeForCamelCase } from "../../../../helper/Quadrant/quadrantHelper";
import { toast } from "react-toastify";
import { PRODUCT_SET_LOGS_DATAHEADS, STOP_LOSS_LOGS_DATAHEADS } from "../../../../constant/productset/productsetconfig";

const StopLossLogsList = ({ logId = "",pageName }) => {
  const { clientId } = useGlobalContext();

  const [allFetchLoaders, setAllFetchLoaders] = useState({
    mainPageLoader: false,
  });
  const [childData, setChildData] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [isChildDataLoading, setIsChildDataLoading] = useState([]);
  const isStopLossPage = pageName === 'stoploss';
  const isProductSetPage = pageName === 'productset';
  const initialDataHeads = isStopLossPage ? STOP_LOSS_LOGS_DATAHEADS : isProductSetPage ? PRODUCT_SET_LOGS_DATAHEADS : []
  const [dataHeads, setdataHeads] = useState(
  initialDataHeads
  );

  const [logsList, setLogsList] = useState([]);
  const logsType = isStopLossPage ? 'Automation' : 'PRODUCT_SET'
  const logsRuleType = isStopLossPage ? 'rule_tracking' : 'PRODUCT_SET_TRACKING'
  async function fetchLogsList(logId) {
    if (!logId) return;
    let tempFiltering = isStopLossPage ?  [
      { field_name: "rule_name", operator: "IN", values: [logId] },
      { field_name: "flag_is_scheduled", operator: "NOT_EQUALS", value: null },
      { field_name: "flag_is_scheduled", operator: "EQUALS", value: 1 },
      { field_name: "change_recommended", operator: "NOT_EQUALS", value: null },
    ] : [{field_name:'product_set_id',operator:"IN",values:[logId]}]
    let encodedFiltering = encodeURIComponent(JSON.stringify(tempFiltering));
    let url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/logs?source=amazon&type=${logsType}&filtering=${encodedFiltering}&rule_type=${logsRuleType}`;
    setAllFetchLoaders({ ...allFetchLoaders, mainPageLoader: true });
    await axios
      .get(url)
      .then((logsList) => {
        let formattedLogsList = logsList?.data.map((log) => {
          const parsedLog = isStopLossPage ? {
            ...log,
            rule_config: JSON.parse(log.rule_config),
            decision: JSON.parse(log.decision),
          } : {
            ...log,
            product_set_config : JSON.parse(log?.product_set_config ?? '{}'),
            decision:JSON.parse(log?.decision ?? '{}')
          }
          return parsedLog
        });
        setLogsList(formattedLogsList);
      })
      .catch((error) => {
        console.error("Error fetching logs list:", error);
        toast.error("Error fetching logs list", {
          toastId: "error-fetching-logs-list",
        });
      })
      .finally(() => {
        setAllFetchLoaders({ ...allFetchLoaders, mainPageLoader: false });
      });
  }

  const getNestedValue = (obj, path) => {
    return path.split("?.").reduce((acc, key) => acc?.[key], obj);
  };

  const fetchChildData = async (track_id, effective_change,rule_name) => {
    let tempFiltering = isStopLossPage ?  [
      { field_name: "track_id", operator: "EQUALS", value: track_id },
      { field_name: "message", operator: "EQUALS", value: effective_change },
      {field_name:"rule_name", operator:"EQUALS",value:rule_name}
    ] : [{field_name:'track_id',operator:"IN",values:[track_id]}]
    const trackingType = isStopLossPage ? 'rule_entity_tracking' : 'PRODUCT_SET_ENTITY_TRACKING'
    let encodedFiltering = encodeURIComponent(JSON.stringify(tempFiltering));
    setIsChildDataLoading((prev) => [...prev, isStopLossPage ? track_id + effective_change + rule_name : track_id]);
    let childUrl = `${process.env.REACT_APP_ADS_API}/client/${clientId}/logs?source=amazon&type=${logsType}&filtering=${encodedFiltering}&rule_type=${trackingType}`;
    await axios
      .get(childUrl)
      .then((childData) => {
        setChildData((prev) => {
          const data = {...prev};
          if(isStopLossPage) {
            data[track_id + effective_change + rule_name] = childData?.data.map((log) => {
              return {
                ...log,
                rule_config: JSON.parse(log?.rule_config ?? "{}"),
                metrices: JSON.parse(log?.metrices ?? "{}"),
              };
            })
          } else {
            data[track_id] = childData?.data?.map((log) => {
              return {
                ...log,
                extra_info_json:JSON.parse(log?.extra_info_json ?? "{}")
              }
            })
          }
          return data
        })
        // setChildData((prev) => ({
        //   ...prev,
        //   [track_id + effective_change + rule_name]:  childData?.data.map((log) => {
        //     return {
        //       ...log,
        //       rule_config: JSON.parse(log?.rule_config ?? "{}"),
        //       metrices: JSON.parse(log?.metrices ?? "{}"),
        //     };
        //   }) // Store fetched child data keyed by parent row ID
        // }));
      })
      .catch((error) => {
        console.error("Error fetching child data:", error);
        toast.error("Error fetching child data", {
          toastId: "error-fetching-child-data",
        });
      })
      .finally(() => {
        const UNIQUE_KEY = isStopLossPage ? track_id + effective_change + rule_name  : track_id 
        setIsChildDataLoading((prev) =>
          prev.filter((id) => id !== UNIQUE_KEY)
        );
      });
  };

  const toggleRow = async (trackId, effective_change,rule_name) => {
    const UNIQUE_KEY = isStopLossPage ? trackId + effective_change + rule_name : trackId
    setExpandedRows((prev) => ({
      ...prev,
      [UNIQUE_KEY]: !prev[UNIQUE_KEY],
    }));
    // Fetch child data if expanding and not already fetched
    if (
      !expandedRows[UNIQUE_KEY] &&
      !childData[UNIQUE_KEY]
    ) {
      await fetchChildData(trackId, effective_change,rule_name);
    }
  };

  useEffect(() => {
    fetchLogsList(logId);
  }, [logId]);

  const renderAdgroupsOrCampaigns = (data, key) => {
    const mappedData = data?.map((childData) => childData[key] ?? '');
    const firstData = mappedData[0] ?? '-';
    const remainingData = mappedData?.slice(1);
  
    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <span>{firstData}</span>
        {remainingData?.length > 0 && (
          <span
          className={styles.purpleBadge}
            title={remainingData?.join(', ')}
            style={{marginLeft:'4px'}}
          >
            +{remainingData.length}
          </span>
        )}
      </div>
    );
  };
  

  let tableBody = (
    <div className="defaultTableContainer lsit-table-scrollbar">
      {logsList?.length ? (
        <table>
          <thead className="fixed-table">
            <tr className="main-table-row-fixed">
              {dataHeads?.map((th, mainIndex) => {
                return (
                  <th
                    style={{ minWidth: th?.minWidth ? th?.minWidth : "auto" }}
                    className={styles.logsTableHead}
                  >
                    <span
                      className="captital-first-letter"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {th.fieldName.toLowerCase()}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          {logsList?.length ? (
            <tbody className="another-tbody">
              {logsList?.map((rowData, index) => {
                const UNIQUE_KEY = isStopLossPage ? rowData?.track_id + rowData?.effective_change + rowData?.rule_name : rowData?.track_id
                const isExpanded =
                  expandedRows[UNIQUE_KEY]; // Check if row is expanded
                const children =
                  childData[UNIQUE_KEY] ||
                  []; // Get child data for this row
                  const productSetConfig = rowData?.product_set_config ?? {}
                  const cubeFilters = isStopLossPage ? {} : JSON.parse(productSetConfig?.cubeFilters ?? '{}');

                return (
                  <React.Fragment
                    key={UNIQUE_KEY}
                  >
                    <tr>
                      {dataHeads?.map((column, index1) => {
                        let cellContent;
                        const entity_list = JSON.parse(rowData?.extra_info_json ?? '{}')?.populated_entity_list ?? []
                        const campaigns = renderAdgroupsOrCampaigns(entity_list,'campaign_name')
                        const adgroups = renderAdgroupsOrCampaigns(entity_list,'adset_name')
                        switch (column?.id) {
                          case "impact":
                            cellContent = (
                              <td
                                onClick={() =>
                                {
                                  if(getNestedValue(rowData, column?.apiKey) == 0) return;
                                  toggleRow(
                                    rowData?.track_id,
                                    rowData?.effective_change,
                                    rowData?.rule_name
                                  )
                                }
                                }
                                key={index1 + 1}
                                className="td-with-loader"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "6px",
                                    minWidth: "max-content",
                                  }}
                                >
                                  {getNestedValue(rowData, column?.apiKey) ||
                                    "0"}{" "}
                                  Products
                                  {(getNestedValue(rowData, column?.apiKey) != 0) && <img
                                    src={
                                      isExpanded ? arrowUpIcon : arrowDownIcon
                                    }
                                    alt="arrow"
                                  />}
                                </div>
                              </td>
                            );
                            break;
                          case "campaign_name":
                            cellContent = (
                              <td className="text-nowrap">
                                {
                                  campaigns ?? '-'
                                }
                              </td>
                            )
                            break;
                            case "adset_name":
                              cellContent = (
                                <td className="text-nowrap">
                                  {
                                    adgroups ?? '-'
                                  }
                                </td>
                              )
                              break;
                          case "ad_spend_saved":
                            cellContent = (
                              <td className="text-nowrap">
                                {rowData?.decision?.extraInfo?.adSpendSaved
                                  ? "₹" +
                                    getNestedValue(
                                      rowData,
                                      column?.apiKey
                                    )?.toFixed(2)
                                  : "-"}
                              </td>
                            );
                            break;
                          case "effective_status":
                            cellContent = (
                              <td className="text-nowrap">
                                {rowData?.effective_change ? (
                                  rowData?.effective_change ===
                                  "PRODUCT_ENABLED" ? (
                                    <span className={styles.blueBadge}>
                                      Included
                                    </span>
                                  ) : rowData?.effective_change ===
                                    "PRODUCT_PAUSED" ? (
                                    <span className={styles.grayBadge}>
                                      Excluded
                                    </span>
                                  ) : (
                                    rowData?.effective_change
                                      ?.split("_")
                                      ?.join(" ")
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                            );
                            break;
                          case "activity_time":
                            cellContent = (
                              <td key={index1 + 1} className="td-with-loader">
                                {rowData?.created_at
                                  ? moment
                                      .utc(rowData?.created_at)
                                      .local()
                                      .format("DD MMM YYYY hh:mm a")
                                  : "-"}
                              </td>
                            );
                            break;
                          case "exclusion_case":
                            cellContent = (
                              <td
                                style={{ width: "200px" }}
                                key={index1 + 1}
                                className="td-with-loader"
                              >
                                {FilterPreview({
                                  filters: JSON.parse(
                                    getCubeFiltersByActionTypeForCamelCase(
                                      rowData?.rule_config?.ruleTasks,
                                      "PAUSE_PRODUCTS"
                                    ) ?? "{}"
                                  )?.query?.filters,
                                })}
                              </td>
                            );
                            break;
                          case "inclusion_case":
                            cellContent = (
                              <td key={index1 + 1} className="td-with-loader">
                                {FilterPreview({
                                  filters: JSON.parse(
                                    getCubeFiltersByActionTypeForCamelCase(
                                      rowData?.rule_config?.ruleTasks,
                                      "ENABLE_PRODUCTS"
                                    ) ?? "{}"
                                  )?.query?.filters,
                                })}
                              </td>
                            );
                            break;
                          case "frequency":
                            cellContent = (
                              <td key={index1 + 1} className="td-with-loader">
                                <p
                                  style={{ minWidth: "130px" }}
                                  className={styles.blueBadge}
                                >
                                  {rowData?.schedule_config?.day_frequency
                                    ? "Running " +
                                      getNestedValue(rowData, column?.apiKey)
                                    : "-"}
                                </p>
                              </td>
                            );
                            break;
                          case "configuration":
                            cellContent = (
                              <td key={index1 + 1} className="td-with-loader">
                                {FilterPreview({
                                  filters: JSON.parse(
                                    productSetConfig?.cubeFilters ?? '{}'
                                  )?.query?.filters
                                })}
                              </td>
                            )
                            break;
                          default:
                            cellContent = (
                              <td key={index1 + 1} className="td-with-loader">
                                { isStopLossPage ? (getNestedValue(rowData, column?.apiKey) || '-') : "-"}
                              </td>
                            );
                        }

                        return <>{cellContent}</>;
                      })}
                    </tr>
                    {/* Child Rows */}
                    {isExpanded &&
                      (children.length > 0
                        ? children?.map((childData, childIndex) => (
                            <tr
                              style={{ background: "#F4F6F8" }}
                              key={`${rowData.id}-${childData.id}`}
                            >
                              {/* Render child row data */}
                              {dataHeads?.map((column, index1) => {
                                const impactAsin = isStopLossPage ? childData?.metrices?.productStatsData
                                ?.asin: isProductSetPage ? childData?.entity_id : null
                               const impactProductNameWithImage = isStopLossPage ? childData?.metrices?.productStatsData?.productNameWithImage : isProductSetPage ? childData?.extra_info_json?.extraInfo?.productNameWithImage : null
                                let cellContent;
                                switch (column?.id) {
                                  case "impact":
                                    cellContent = (
                                      <td
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        {getProductWithImageForLogs(
                                         impactProductNameWithImage,
                                          impactAsin
                                        ) ?? '-'}
                                      </td>
                                    );
                                    break;
                                  case "ad_spend_saved":
                                    cellContent = (
                                      <td className="text-nowrap">
                                        {childData?.metrices?.productStatsData
                                          ?.cost
                                          ? "₹" +
                                            childData?.metrices?.productStatsData?.cost?.toFixed(
                                              2
                                            )
                                          : "-"}
                                      </td>
                                    );
                                    break;
                                  case "effective_status":
                                    cellContent = (
                                      <td className="text-nowrap">
                                        <td className="text-nowrap">
                                          {rowData?.effective_change ? (
                                            rowData?.effective_change ===
                                            "PRODUCT_ENABLED" ? (
                                             
                                               "Product Included"
                                             
                                            ) : rowData?.effective_change ===
                                              "PRODUCT_PAUSED" ? (
                                             
                                              "Product Excluded"
                                              
                                            ) : (
                                              rowData?.effective_change
                                                ?.split("_")
                                                ?.join(" ")
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </td>
                                    );
                                    break;
                                  case "activity_time":
                                    cellContent = (
                                      <td
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        {childData?.created_at
                                          ? moment
                                              .utc(childData?.created_at)
                                              .local()
                                              .format("DD MMM YYYY hh:mm a")
                                          : "-"}
                                      </td>
                                    );
                                    break;
                                  case "exclusion_case":
                                    cellContent = (
                                      <td
                                        style={{ width: "200px" }}
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        {FilterPreview({
                                          filters: JSON.parse(
                                            getCubeFiltersByActionTypeForCamelCase(
                                              rowData?.rule_config?.ruleTasks,
                                              "PAUSE_PRODUCTS"
                                            ) ?? "{}"
                                          )?.query?.filters,
                                        })}
                                      </td>
                                    );
                                    break;
                                  case "inclusion_case":
                                    cellContent = (
                                      <td
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        {FilterPreview({
                                          filters: JSON.parse(
                                            getCubeFiltersByActionTypeForCamelCase(
                                              rowData?.rule_config?.ruleTasks,
                                              "ENABLE_PRODUCTS"
                                            ) ?? "{}"
                                          )?.query?.filters,
                                        })}
                                      </td>
                                    );
                                    break;
                                  case "frequency":
                                    cellContent = (
                                      <td
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        <p
                                          style={{ minWidth: "130px" }}
                                          className={styles.blueBadge}
                                        >
                                          {childData?.schedule_config
                                            ?.day_frequency
                                            ? "Running " +
                                              getNestedValue(
                                                childData,
                                                column?.apiKey
                                              )
                                            : "-"}
                                        </p>
                                      </td>
                                    );
                                    break;
                                  case "configuration":
                                      cellContent = (
                                        <td key={index1 + 1} className="td-with-loader">
                                          {FilterPreview({
                                            filters: 
                                              cubeFilters
                                            ?.query?.filters,
                                          })}
                                        </td>
                                      )
                                      break;
                                  default:
                                    cellContent = (
                                      <td
                                        key={index1 + 1}
                                        className="td-with-loader"
                                      >
                                        {getNestedValue(
                                          childData,
                                          column?.apiKey
                                        ) || "-"}
                                      </td>
                                    );
                                }

                                return <>{cellContent}</>;
                              })}
                            </tr>
                          ))
                        : isChildDataLoading?.includes(
                            UNIQUE_KEY
                          )
                        ? generateShimmerTable(4, dataHeads.length)
                        : null)}
                  </React.Fragment>
                );
              })}
            </tbody>
          ) : (
            <div>{/* <p>NO DATA FOUND</p> */}</div>
          )}
        </table>
      ) : (
        <div className={styles.stopLossLogsLoader}>
          {/* <p>NO DATA FOUND</p> */}
          <Spinner size="lg" shape="border" />
        </div>
      )}
    </div>
  );

  return (
    <div className="campaign-list-table-data">
      <Stack className="campaign-list-main-table">
        <TableRb
          table={tableBody}
          noDataFound={!logsList?.length && !allFetchLoaders?.mainPageLoader}
        ></TableRb>
      </Stack>
    </div>
  );
};

function StopLossLogsModal({ showLogsModal, setShowLogsModal,pageName }) {
  return (
    <Modal
      dialogClassName={styles.popupModal}
      centered={true}
      show={showLogsModal}
      animation={true}
      scrollable={true}
      onHide={() => setShowLogsModal(false)}
      seprarator={true}
    >
      <Modal.Header className="p-2 h-6" closeButton>
        <Modal.Title>Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StopLossLogsList pageName={pageName} logId={showLogsModal} />
      </Modal.Body>
    </Modal>
  );
}

export default StopLossLogsModal;