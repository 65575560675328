import React from 'react';
import styles from './CustomPagination.module.css';

const CustomPagination = ({ currentPage, totalPages, onPageChange, lazyLoading }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const displayRange = 2; // Number of pages to display on each side of the current page
    let rangeStart = Math.max(1, currentPage - displayRange);
    let rangeEnd = Math.min(totalPages, currentPage + displayRange);
    const pages = [];

    if (rangeStart > 1) {
      pages.push(1);
      if (rangeStart > 2) {
        pages.push('left');
      }
    }
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i);
    }

    if (rangeEnd < totalPages) {
      if (rangeEnd < totalPages - 1) {
        pages.push('right');
      }
      pages.push(totalPages);
    }

    return pages.map((page, index) => (
      <li
        key={index}
        className={`${styles.paginationItem} ${currentPage === page ? styles.active : ''}`}
      >
        {page === 'left' || page === 'right' ? (
          <button 
            className={`${styles.paginationButton} ${styles.ellipsis}`}
            onClick={() => handleEllipsisClick(page)}
            disabled={lazyLoading}
          >
            ...
          </button>
        ) : (
          <button 
            className={styles.paginationButton}
            onClick={() => onPageChange(page)} 
            disabled={lazyLoading}
          >
            {page}
          </button>
        )}
      </li>
    ));
  };

  const handleEllipsisClick = (direction) => {
    const jump = 5; // Number of pages to jump
    let newPage;
    if (direction === 'left') {
      newPage = Math.max(1, currentPage - jump);
    } else {
      newPage = Math.min(totalPages, currentPage + jump);
    }
    onPageChange(newPage);
  };

  return (
    <div className={styles.paginationContainer}>
      <ul className={styles.pagination}>
        <li className={styles.paginationItem}>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1 || lazyLoading}
            className={`${styles.paginationButton} ${styles.paginationNav}`}
          >
            Prev
          </button>
        </li>
        {renderPageNumbers()}
        <li className={styles.paginationItem}>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages || lazyLoading}
            className={`${styles.paginationButton} ${styles.paginationNav}`}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default CustomPagination;
